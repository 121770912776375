import React from "react";
import { Card, ListGroup, Badge } from "react-bootstrap";
import MechanicalParametersViewer from "./MechanicalParametersVIewer";

export default function ResultViewer({ result, masonryCategory }) {

     if (!result)
          return null;
     var variant = {};
     const getVariant = (category) => {
          if (category === "A")
               return "success";
          if (category === "B")
               return "warning"
          if (category === "C")
               return "danger"
          return "primary";
     }
     variant.v = getVariant(result.categories.v);
     variant.np = getVariant(result.categories.np);
     variant.fp = getVariant(result.categories.fp);

     return (
          <React.Fragment>

               <Card.Body>
                    <Card.Title>Risultati</Card.Title>
                    <ListGroup>
                         <ListGroup.Item variant={variant.v} > Azioni Verticali : {result.categories.v} ({result.iqm.v}) </ListGroup.Item>
                         <ListGroup.Item variant={variant.fp}> Azioni Fuori Piano : {result.categories.fp} ({result.iqm.fp})</ListGroup.Item>
                         <ListGroup.Item variant={variant.np}> Azioni Nel Piano : {result.categories.np} ({result.iqm.np})</ListGroup.Item>

                    </ListGroup>
                    {result.iqm.fp <= 4 &&
                                   <Badge pill variant="danger">Disgregazione muraria possibile in caso di sisma di elevata intensità</Badge>}
               </Card.Body><MechanicalParametersViewer masonryCategory = {masonryCategory} parameters={result.mechanicalParameters} />
          </React.Fragment>);
}