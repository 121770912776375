import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { UserContext } from '../../UserContext';
import axios from "axios";

export default function CameraUpload({ name, multiple, showCamera, setShowCamera }) {
    const { setValue, getValues } = useFormContext()
    const { setFeedback } = useContext(UserContext);
    async function handleTakePhoto(dataUri) {
        console.log(dataUri);
        var newValue = null;
        try {
            
            var res = await axios.post("api/resources/", { file: dataUri });
            if (res.status >= 400) {
              setFeedback({
                variant: "danger",
                message: "Qualcosa è andato storto durante il caricamento dell'immagine"
              });
              return;
            } else {
              setFeedback({variant: "success", message:"Immagine caricata correttamente"});
              newValue = res.data.filehandle;
            }
      
          } catch (error) {
              setFeedback({variant: "danger", message:"Qualcosa è andato storto!"});
              return;
          }

          
        if (multiple) {
            var oldValues = getValues(name, []);
            if(!oldValues)
                oldValues = [];
            newValue = [...oldValues, newValue, ];
        }

        setValue(name, newValue);
        setShowCamera(false);
    }


    return (
        showCamera && <Camera

            onCameraError={() => {
                setFeedback({
                    variant: "danger",
                    message: "Errore: accesso alla fotocamera non consentito"
                });
                setShowCamera(false);
            }}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.80}
            isImageMirror={false}
            isSilentMode={false}
            sizeFactor={1}
            onTakePhotoAnimationDone={(dataUri) => { handleTakePhoto(dataUri); }}
        />
    );
}