import React from "react"
export default function MechanicalParameterViewer(props) {

const tau0 = "\u03C4\u2080";
const fv0 = "fv\u2080"
const fm = "f\u2098"

const chooseName = (name) => {
    switch (name){
        case "tau0": 
            return tau0;
        case "fv0":
            return fv0;
        case "fm":
            return fm;
        default:
            return name;
    }
}
    return (
        <React.Fragment>
            <tr>
                <td>{chooseName(props.parameterName)}</td>
                <td>{props.parameter.min} </td>
                <td>{props.parameter.med} </td>
                <td>{props.parameter.max} </td>
            </tr>
        </React.Fragment>
    )
}
