import axios from "axios"

export function isLogged (user) {
   return !!user;
 }

 export function isAdmin (user, onlyAdmin = false) {
   return !!user && (user.privileges === "ADMIN" || 
   (!onlyAdmin && user.privileges === "SUPERVISOR"));
}

export function isAdvanced (user) {
   return user.privileges === "ADVANCED";
}

 export function doLogout (setUser) {
   axios.get("/api/users/logout").catch(error => console.log(error))
   localStorage.clear();
   setUser(null);
}