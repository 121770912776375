import React from "react";

export default function PrivacyPolicy() {
    return (<div>
        <p align="left">
    <strong>Privacy Policy</strong>
    <br/>
    Documento elaborato ai sensi e per gli effetti:
    <br/>
    - del D. Lgs. 30 giugno 2003 n. 196
</p>
<p align="left">
    - del GDPR (General Data Protection Regulation) 2016/679 in vigore dal 25
    maggio 2018
</p>
<h1 lang="it">
    GUIDA AL TRATTAMENTO DATI
</h1>
<p align="left">
    <li>1. Generalità</li><br/>
    <li>2. Tipi di dati</li><br/>
    <li>3. Protezione dei dati</li><br/>
    <li>4. Periodo di conservazione dei dati</li><br/>
    <li>5. Modifiche alla policy</li>
</p>
<h1 href="#1">
    
    1. Generalità
</h1>
<p align="left">
    AEDES Software snc (nel seguito: AEDES), in linea con la normativa sopra
    indicata, intende garantire la privacy e la sicurezza dei dati personali
    forniti direttamente o via web da ciascun utente/visitatore (nel seguito:
    Utente, o: Utenti), nonché la privacy e la sicurezza dei dati del proprio
    personale dipendente e dei collaboratori professionali a vario titolo (nel
    seguito: Personale).
    <br/>
    La presente Guida descrive le modalità di trattamento dei dati negli uffici
    di AEDES, mentre per quanto riguarda la gestione del sito    <u><a href="http://www.iqmindex.com/">www.iqmindex.com</a></u>,   
    si rimanda ai Termini d'uso pubblicati nel sito stesso. in Allegato A
    si riporta la Cookie Policy di AEDES Software.
</p>
<h1 href="#2">
    
    
    2. Tipi di dati
</h1>
<p align="left">
    L'Utente può fornire i propri dati direttamente ad AEDES, ad esempio per
telefono o via e-mail, o attraverso il sito    <u><a href="http://www.iqmindex.com/">www.iqmindex.com</a></u>. In ogni momento
    l'Utente può richiedere la cancellazione dei propri dati.<br/>
    I dati forniti consistono in tutti quelli necessari per lo svolgimento dei
    servizi AEDES richiesti dallo stesso Utente e descritti in dettaglio nei Termini d'uso.
</p>
<h1 href="#3">
    
    3. Protezione dei dati
</h1>
<p align="left">
    La protezione dei dati e la loro integrità vengono assicurate attraverso le
    seguenti procedure:
    <br/>
    - firewall (che possono essere di tipo hardware e/o software)
    <br/>
    - antivirus
    <br/>
    - aggiornamento dei sistemi operativi connessi a Internet
    <br/>
    - password per l'accesso ai servizi
</p>
<p align="left">
    - database Utenti accessibile solo mediante password dell'amministratore
    del sistema (PC o server)
</p>
<p align="left">
    
    - backup periodico con possibilità di ripristino a partire sia da copie
    software sia da documentazione cartacea (ad es.: i dati forniti dall'Utente
    per lo svolgimento dei servizi AEDES richiesti dall'Utente medesimo).
</p>
<p lang="it" align="left">
    I dati dell'Utente potranno essere comunicati a terzi per adempiere ad
    obblighi di legge, ovvero per rispettare ordini provenienti da pubbliche
    autorità ovvero per esercitare un diritto in sede giudiziaria.
</p>
<p lang="it" align="left">
    In nessun altro caso i dati sensibili dell'utente, ove presenti,
    verranno condivisi con altri soggetti.
</p>
<h1 href="#4">
    
    
    
    4. Periodo di conservazione dei dati
</h1>
<p align="left">
    Il tempo di conservazione dei dati, definito in relazione alla finalità del
    trattamento, viene esteso al massimo fino alla data di prosecuzione
    dell'attività di AEDES oppure fino a nuova informativa che contenga
    modifiche sul trattamento tali da richiedere un rinnovo di consenso da
    parte dell'Utente.
</p>
<h1 href="#5">
    
    
    5. Modifiche alla policy
</h1>
<p align="left">
    La presente Policy regola le modalità di trattamento dei dati personali
    forniti dagli Utenti e dal Personale. L’eventuale entrata in vigore di
    nuove normative di settore potrebbe comportare la necessità di variare tali
    modalità. È pertanto possibile che la nostra policy subisca modifiche nel
    tempo ed invitiamo quindi l’Utente a consultare periodicamente questa
    Guida.
</p>
<p align="left">
    <em>San Miniato, Pisa, 15.02.2021</em>
</p>


<p lang="it" align="left">
    <strong>Allegato A</strong>
</p>
<p align="left">
    
    <strong>COOKIE POLICY DI AEDES Software</strong>
</p>
<p align="left">
    <br/>
    I Cookie sono costituiti da porzioni di codice installate all'interno del
    browser che assistono il Titolare nell’erogazione del Servizio in base alle
    finalità descritte. Alcune delle finalità di installazione dei Cookie
    potrebbero, inoltre, necessitare del consenso dell'Utente.
    <br/>
    Quando l’installazione di Cookies avviene sulla base del consenso, tale
    consenso può essere revocato liberamente in ogni momento seguendo le
    istruzioni contenute in questo documento.
</p>
<p>
    Il sito <u><a href="http://www.iqmindex.com/">www.iqmindex.com</a></u> (nel
    seguito: “Applicazione”) raccoglie alcuni Dati Personali. Tali Dati
    Personali sono raccolti per le finalità e sono trattati secondo le modalità
    di seguito riportate.
</p>
<p align="left">
    <br/>
    <strong>TITOLARE DEL TRATTAMENTO DEI DATI</strong>
</p>
<p align="left">
    Aedes Software s.n.c.
</p>
<p align="left">
    Via Ferrante Aporti, 32 - 56028 San Miniato (PI)
</p>
<p align="left">
    info@iqmindex.com
</p>
<p align="left">
    aedes@pec.leonet.it
</p>
<p align="left">
    P.IVA e CF 01735120972
</p>
<p align="left">
    Codice REA: PI-142866
</p>
<p align="left">
    L’elenco aggiornato dei responsabili del trattamento potrà essere
    costantemente verificato contattando il Titolare presso la sua sede
    amministrativa a mezzo servizio postale. Responsabili ed incaricati del
    trattamento si intendono il personale, ed i collaboratori interni od
    esterni appositamente nominati.
</p>
<p align="left">
    <strong>Tipologie di dati raccolti</strong>
</p>
<p align="left">
    Fra i Dati Personali trattati dalla presente Applicazione ci sono: Nome,
    email, Cookie e Dati di navigazione ed utilizzo.
</p>
<p align="left">
    Altri Dati Personali raccolti potrebbero essere evidenziati nel resto di
    questa privacy policy o con del testo informativo contestuale alla raccolta
    dei Dati stessi.
</p>
<p align="left">
    I Dati Personali possono essere inseriti volontariamente dall’Utente o
    dall’Interessato, oppure raccolti in modo automatico, tramite la
    navigazione.
</p>
<p align="left">
    L’eventuale utilizzo di Cookie da parte dell’Applicazione o dei titolari
    dei servizi terzi di cui l’Applicazione si avvale, ove non diversamente
    precisato, ha la finalità di identificare l’Utente e registrare le relative
    preferenze per finalità strettamente legate allo svolgimento del servizio
    richiesto dall’Utente.
</p>
<p align="left">
    Il mancato conferimento da parte dell’Utente di alcuni Dati Personali, ed
    in particolare dei Dati di Navigazione mediante la disattivazione dei
    Cookie dell’Applicazione, potrebbe rendere impossibile la prestazione di
    alcuni servizi o la navigazione.
</p>
<p align="left">
    I Dati Personali raccolti possono riferirsi sia all’Utente, sia a soggetti
    terzi di cui l’Utente fornisce i dati.
</p>
<p align="left">
    L'Utente si assume la responsabilità dei Dati personali di terzi pubblicati
    o condivisi mediante l’Applicazione e garantisce di avere il diritto di
    comunicarli o diffonderli liberando il Titolare del Trattamento da
    qualsiasi responsabilità verso terzi.
</p>
<p align="left">
    <br/>
    <strong>Modalità e luogo del trattamento dei dati raccolti</strong>
</p>
<p align="left">
    <em>Modalità di trattamento</em>
</p>
<p align="left">
    Il Titolare tratta i Dati Personali degli Utenti e degli Interessati
    adottando le opportune misure di sicurezza volte ad impedire l’accesso, la
    divulgazione, la modifica o la distruzione non autorizzate dei Dati
    Personali. Il trattamento viene effettuato mediante strumenti informatici
    con modalità organizzative strettamente correlate alle finalità indicate di
    seguito.
</p>
<p align="left">
    Oltre al titolare, in alcuni casi, potrebbero avere accesso ai Dati
    soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
    postali, hosting provider, società informatiche, agenzie di comunicazione).
    L’elenco aggiornato dei soggetti potrà sempre essere richiesto al Titolare
    del Trattamento.
</p>
<p align="left">
    <em>Luogo</em>
</p>
<p align="left">
    I Dati sono trattati presso la sede del Titolare del Trattamento, salvo
    quanto altrimenti specificato nel resto del documento.
</p>
<p align="left">
    <em>Tempi</em>
</p>
<p align="left">
    I Dati sono trattati per il tempo necessario allo svolgimento del servizio
    richiesto dall’Utente e l’Utente può sempre chiedere l’interruzione del
    Trattamento o la cancellazione.
</p>
<p align="left">
    <strong>Finalità del trattamento dei dati raccolti</strong>
</p>
<p align="left">
    I Dati dell’Utente o dell'Interessato sono raccolti per le seguenti
    finalità: Contatto via email, Gestione dei database di utenti e Statistica.
</p>
<p align="left">
    Le tipologie di Dati Personali utilizzati per ciascuna finalità sono
    indicati nelle sezioni specifiche di questo documento.
</p>
<p align="left">
    L’Utilizzo dei Dati per ulteriori finalità da parte del Titolare potrebbe,
    in alcuni casi e per specifiche legislazioni di riferimento, richiedere il
    consenso dell’Utente o dell’Interessato.
</p>
<p align="left">
    <br/>
    <strong>
        Dettagli sul trattamento dei dati personali e sui servizi forniti da
        soggetti terzi
    </strong>
</p>
<p align="left">
    I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i
    seguenti servizi:
</p>
<p align="left">
    • Contatto via email - mailing list
</p>
<p align="left">
    A seguito della registrazione alla mailing list / newsletter, l’indirizzo
    email dell’Utente viene automaticamente inserito in una lista di contatti a
    cui potranno essere trasmessi una newsletter periodica ed informazioni,
    anche di natura commerciale e promozionale, relative ad attività
    inerenti l'Applicazione e l'Autore dell'Applicazione (AEDES Software).
</p>
<p align="left">
    Dati personali raccolti: nome (che l'Utente può fornire come pseudonimo),
    email.
</p>
<p align="left">
    • Modulo di contatto (questo sito web)
</p>
<p align="left">
    L’Utente, compilando con i propri Dati il Modulo di Contatto, acconsente al
    loro utilizzo per rispondere alle richieste di informazioni, di preventivo,
    o di qualunque altra natura indicata dall’intestazione del modulo.
</p>
<p align="left">
    Dati personali raccolti: nome (che l'Utente può fornire come pseudonimo) e
    email.
</p>
<p align="left">
    • Interazione con social network e piattaforme esterne
</p>
<p align="left">
    Questi servizi permettono di effettuare interazioni con i social network, o
    con altre piattaforme esterne, direttamente dalle pagine dell’Applicazione.
    Le interazioni e le informazioni acquisite da questa Applicazione sono in
    ogni caso soggette alle impostazioni privacy dell’Utente relative ad ogni
    social network.
</p>
<p align="left">
    Nel caso in cui sia installato un servizio di interazione con i social
    network, è possibile che, anche nel caso gli Utenti non utilizzino il
    servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è
    installato.
</p>
<p align="left">
    • Pulsante Tweet e widget sociali di Twitter (Twitter).
</p>
<p align="left">
    Il pulsante Tweet e i widget sociali di Twitter sono servizi di interazione
    con il social network Twitter, forniti da Twitter Inc.
</p>
<p align="left">
    Dati personali raccolti: Cookie e Dati di navigazione ed utilizzo.
</p>
<p align="left">
    Luogo del Trattamento: USA – Privacy Policy
</p>
<p lang="en-US" align="left">
    • Widget Google Maps (Google inc.)
</p>
<p align="left">
    Google Maps è un servizio di visualizzazione di mappe gestito da Google
    Inc. che permette a questa Applicazione di integrare tali contenuti
    all’interno delle proprie pagine.
</p>
<p align="left">
    Dati personali raccolti: Cookie e Dati di navigazione ed utilizzo.
</p>
<p align="left">
    Luogo del Trattamento: USA – Privacy Policy
</p>
<p align="left">
    <strong>
        <br/>
        Registrazione ed autenticazione
    </strong>
</p>
<p align="left">
    Con la registrazione o l’autenticazione l’Utente consente all’Applicazione
    di identificarlo e di dargli accesso a servizi dedicati.
</p>
<p align="left">
    A seconda di quanto indicato di seguito, i servizi di registrazione e di
    autenticazione potrebbero essere forniti con l’ausilio di terze parti.
</p>
<p align="left">
    Qualora questo avvenga, questa applicazione potrà accedere ad alcuni Dati
    conservati dal servizio terzo usato per la registrazione o
    l’identificazione.
</p>
<p align="left">
    <br/>
    <strong>Registrazione gestita dall’applicazione (questo sito web)</strong>
</p>
<p align="left">
    L’Utente si registra compilando il modulo di registrazione e fornendo
    direttamente all’Applicazione i propri Dati Personali.
</p>
<p align="left">
    Dati personali raccolti: Nome (che l'Utente può fornire come pseudonimo),
    Username, email, Password.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>Statistica</strong>
</p>
<p align="left">
    I servizi contenuti nella presente sezione permettono al Titolare del
    Trattamento di monitorare e analizzare i dati di traffico e servono a tener
    traccia del comportamento dell’Utente.
</p>
<p align="left">
    • Google Analytics (Google)
</p>
<p align="left">
    Google Analytics è un servizio di analisi web fornito da Google Inc.
    (“Google”). Google utilizza i Dati Personali raccolti allo scopo di
    tracciare ed esaminare l’utilizzo dell’Applicazione, compilare report e
    condividerli con gli altri servizi sviluppati da Google.
</p>
<p align="left">
    Google potrebbe utilizzare i Dati Personali per contestualizzare e
    personalizzare gli annunci del proprio network pubblicitario.
</p>
<p align="left">
    Dati personali raccolti: Cookie e Dati di navigazione ed utilizzo.
</p>
<p align="left">
    Luogo del Trattamento: USA – Privacy Policy
</p>
<p align="left">
    <br/>
    <strong>Ulteriori informazioni sul trattamento</strong>
</p>
<p align="left">
    • Difesa in giudizio
</p>
<p align="left">
    I Dati Personali dell’Utente possono essere utilizzati per la difesa da
    parte del Titolare dell’Applicazione in giudizio o nelle fasi propedeutiche
    alla sua eventuale instaurazione, da abusi nell'utilizzo della stessa o dei
    servizi connessi da parte dell’Utente.
</p>
<p align="left">
    • Informative specifiche
</p>
<p align="left">
    Informative specifiche potrebbero essere presentate nelle pagine del Sito
    in relazione a particolari servizi o trattamenti dei Dati forniti
    dall’Utente o dall’Interessato.
</p>
<p align="left">
    • Manutenzione
</p>
<p align="left">
    I Dati Personali dell’Utente possono essere trattati con modalità e
    finalità aggiuntive legate alla manutenzione dell’Applicazione.
</p>
<p align="left">
    • Log di sistema
</p>
<p align="left">
    Per necessità legate al funzionamento ed alla manutenzione, questa
    Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero
    raccogliere Log di sistema, ossia file che registrano le interazioni –
    compresa la navigazione – e che possono contenere anche Dati Personali,
    quali l’indirizzo IP.
</p>
<p align="left">
    • Informazioni non contenute in questa policy
</p>
<p align="left">
    Maggiori informazioni in relazione al trattamento dei Dati Personali
    potranno essere richieste in qualsiasi momento al Titolare del Trattamento.
</p>
<p align="left">
    • Esercizio dei diritti da parte degli interessati
</p>
<p align="left">
    I soggetti cui si riferiscono i Dati Personali hanno il diritto in
    qualunque momento di ottenere la conferma dell'esistenza o meno degli
    stessi presso il Titolare del Trattamento, di conoscerne il contenuto e
    l'origine, di verificarne l'esattezza o chiederne l’integrazione, la
    cancellazione, l'aggiornamento, la rettifica, la trasformazione in forma
    anonima o il blocco dei Dati Personali trattati in violazione di legge,
    nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.
    Le richieste vanno rivolte al Titolare del Trattamento.
</p>
<p align="left">
    • Modifiche a questa privacy policy
</p>
<p align="left">
    L’Applicazione si riserva il diritto di apportare modifiche alla presente
    Privacy Policy in qualunque momento dandone pubblicità agli Utenti su
    questa pagina e garantendo in ogni caso una analoga protezione dei Dati
    Personali. Si prega dunque di consultare spesso questa pagina, prendendo
    come riferimento la data di ultima modifica indicata in fondo.
</p>
<p align="left">
    <strong>
        <br/>
        Ulteriori informazioni generali
    </strong>
</p>
<p align="left">
    La presente informativa privacy è redatta in via autonoma e con piena
    assunzione di responsabilità dal Titolare del Trattamento dei Dati
    Personali, partendo da moduli predisposti da Iubenda e conservati sui suoi
    server.
</p>
<p align="left">
    <strong>Definizioni e riferimenti legali</strong>
</p>
<p align="left">
    • Dati personali o dati
</p>
<p align="left">
    Costituisce dato personale qualunque informazione relativa a persona
    fisica, identificata o identificabile, anche indirettamente, mediante
    riferimento a qualsiasi altra informazione, ivi compreso un numero di
    identificazione personale.
</p>
<p align="left">
    • Dati di navigazione
</p>
<p align="left">
    Sono i dati personali raccolti in maniera automatica dall’Applicazione, tra
    i quali: gli indirizzi IP o i nomi a dominio dei computer utilizzati
    dall’Utente che si connette all’Applicazione, gli indirizzi in notazione
    URI (Uniform Resource Identifier), l’orario della richiesta, il metodo
    utilizzato nel sottoporre la richiesta al server, la dimensione del file
    ottenuto in risposta, il codice numerico indicante lo stato della risposta
    dal server (buon fine, errore, ecc.) il Paese di provenienza, le
    caratteristiche del browser e del sistema operativo utilizzati dal
    visitatore, le varie connotazioni temporali della visita (ad esempio il
    tempo di permanenza su ciascuna pagina) e i dettagli relativi
    all’itinerario seguito all’interno dell’Applicazione, con particolare
    riferimento alla sequenza delle pagine consultate, ai parametri relativi al
    sistema operativo e all’ambiente informatico dell’Utente.
</p>
<p align="left">
    • Utente
</p>
<p align="left">
    Il soggetto fruitore di servizi o di prodotti dell’Applicazione.
</p>
<p align="left">
    • Interessato
</p>
<p align="left">
    La persona fisica o giuridica cui si riferiscono i Dati Personali.
</p>
<p align="left">
    • Responsabile del trattamento
</p>
<p align="left">
    La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro
    ente, associazione od organismo preposti dal titolare al trattamento dei
    dati personali.
</p>
<p align="left">
    • Titolare del trattamento
</p>
<p align="left">
    La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro
    ente, associazione od organismo cui competono, anche unitamente ad altro
    titolare, le decisioni in ordine alle finalità, alle modalità del
    trattamento di dati personali ed agli strumenti utilizzati, ivi compreso il
    profilo della sicurezza, in relazione al funzionamento e alla fruizione del
    presente Sito.
</p>
<p align="left">
    • Riferimenti legali
</p>
<p align="left">
    Avviso agli utenti europei: la presente informativa privacy è redatta in
    adempimento degli obblighi previsti dall’Art. 10 della Direttiva n.
    95/46/CE, nonché a quanto previsto dalla Direttiva 2002/58/CE, come
    aggiornata dalla Direttiva 2009/136/CE, in materia di Cookie.
</p>
<p align="left">
    Questa informativa privacy riguarda esclusivamente la presente
    Applicazione.
</p>
<p align="left">
    San Miniato, Pisa, 15.02.2021
</p>
</div>
    )
}