import React from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap"
import { parameterList } from "../../../parameterList";


export default function ParameterList({ values }) {
    return (
        values && <React.Fragment>

            <Table responsive bordered striped>
                <thead>
                    <tr>
                        {
                            parameterList
                                .map((param, index) => (
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{param.long}</Tooltip>}>
                                        <th as ="span">
                                            <p style={{ pointerEvents: 'none' }} key={"name" + index}>{param.short}</p>
                                        </th>
                                    </OverlayTrigger>


                                ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            parameterList
                                .map((param, index) => (
                                    <td key={index + "eval"}>{values[param.short.split(".").join("")]}</td>
                                ))}
                    </tr>
                </tbody>

            </Table>
        </React.Fragment>
    );
};
