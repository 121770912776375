import React from "react";
import { Form } from "react-bootstrap";
import FormCheck from "./FormCheck";
import FormInput from "./FormInput";
import { useFormContext } from "react-hook-form";

export default function LMT({ defaultValue }) {
    const { watch, setValue } = useFormContext();
    const SG = watch("lmt.SG.use", false);
    const PD = watch("lmt.PD.use", false);
    
    const onChangeLMT = (event) => {
       
        if (event.target.value) {
            var param = "NR";
            var val = parseFloat(event.target.value);
            var target = event.target.name.includes("SG") ? "SG" : event.target.name.includes("PD") ? "PD" : null
            if (!target) return;
            if (target === "PD") {
                if (val < 125.0)
                    param = "NR"
                else if (val > 155.0)
                    param = "R"
                else
                    param = "PR"
            } else if (target === "SG") {
                if (val < 140.0) 
                    param = "NR"
                else if (val > 160.0)
                    param = "R"
                else
                    param = "PR"
            }
            setValue("artRuleParameters." + target, param, { shouldValidate: true, shouldDirty: true });
        }

    };
    return (
        <React.Fragment >
            <Form.Label>Linea di Minimo Tracciato</Form.Label>
            <FormCheck defaultValue={defaultValue ? defaultValue.PD.use : false} name="lmt.PD.use" label="Valutazione di P.D. tramite LMT trasversale" />
            {PD && <FormInput onChange={onChangeLMT} defaultValue={defaultValue ? defaultValue.PD.value : 0} type="number" name="lmt.PD.value" label="LMT trasversale" />}
            <FormCheck defaultValue={defaultValue ? defaultValue.SG.use : false} name="lmt.SG.use" label="Valutazione di S.G. tramite LMT frontale" />
            {SG && <FormInput onChange={onChangeLMT} defaultValue={defaultValue ? defaultValue.SG.value : 0} type="number" name="lmt.SG.value" label="LMT frontale" />}
        </React.Fragment>
    );
}