import React from "react"
import { Form, FormCheck } from "react-bootstrap"
import { useFormContext } from "react-hook-form"

export default function CorrectiveParameters({ defaultValue = { m: false, g: false } }) {
    const {register, watch} = useFormContext();
    const r = watch("correctiveParameters.r", false);   
    return (
        <Form.Group>
            <Form.Label>Parametri Correttivi</Form.Label>
            <FormCheck ref={register} defaultChecked={defaultValue ? defaultValue.m : false} name="correctiveParameters.m" label="Applica correzione per malta di pessima qualità" />
            <FormCheck ref={register} defaultChecked={defaultValue ? defaultValue.r : false} name="correctiveParameters.r" label="Applica correzione per mattoni pieni o blocchi equivalenti" />
            {r && <FormCheck ref={register} defaultChecked={defaultValue ? defaultValue.g : false} name="correctiveParameters.g" label="Applica correzione per giunti ampi" />}
        </Form.Group>
    )
}