import React from "react"
import { ButtonGroup, Button, Row, Col, Card } from "react-bootstrap"
import { useHistory } from "react-router-dom";
import { isLogged } from "../auth";
import { UserContext } from "./UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faBookOpen } from '@fortawesome/free-solid-svg-icons'

export default function Home() {
    const history = useHistory();
    const { user } = React.useContext(UserContext);
    return (

        <React.Fragment >
            <Row style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                <Col>
                    <h1>Benvenuto su IQMIndex.com</h1>
                    <small>(Versione 2021.1)</small>
                    <p>IQMIndex implementa il metodo dell'Indice di Qualità Muraria di Antonio Borri e Alessandro De Maria</p>
                    <Button as="a" variant="danger" download href="https://www.aedes.it/documents/20142/880580/InfoAppIQMIndex.pdf" >Istruzioni per l'utilizzo</Button>
                </Col>
            </Row>
            
            <Row style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                {
                    !isLogged(user) && 
					<Col>
                    <Card>
                    <Card.Header>
                        <Card.Title>Video di introduzione a IQMIndex.com</Card.Title>
                        <Card.Subtitle>Accedi o Registrati per utilizzare l'applicazione</Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                    <iframe width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/_72Sv3Hh0Hk" 
                        frameborder="0"
                        title="video_introduttivo" 
                        allow="accelerometer; 
                                autoplay; 
                                clipboard-write; 
                                encrypted-media; 
                                gyroscope; 
                                picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                    </Card.Body>
                </Card>
					</Col>
				}
				{
                    !isLogged(user) && 
					<Col>
                    <Card>
                    <Card.Header>
                        <Card.Title>Metodo IQM (1/3)</Card.Title>
                        <Card.Subtitle>Il video mostra la collocazione del Metodo IQM nel percorso conoscenza - valutazione rischio disgregazione - analisi strutturali.</Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                    <iframe 
						width="560" 
						height="315" 
						src="https://www.youtube.com/embed/SssLUkWCe7U" 
						title="YouTube video player" 
						frameborder="0" 
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
						allowfullscreen>
					</iframe>
					</Card.Body>
						<Card.Footer>
							Relatore: Ing. Giulio Castori
						</Card.Footer>
                </Card>
                </Col>
                }
				{
                    !isLogged(user) && 
					<Col>
                    <Card>
                    <Card.Header>
                        <Card.Title>Metodo IQM (2/3)</Card.Title>
							<Card.Subtitle>Il video descrive il metodo dal punto di vista della valutazione qualitativa</Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
					<iframe width="560" height="315" src="https://www.youtube.com/embed/UzhC4zpMGfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Card.Body>
						<Card.Footer>
							Relatore: Prof. Ing. Antonio Borri
						</Card.Footer>
                </Card>
                </Col>
                }
				{
                    !isLogged(user) && 
					<Col>
                    <Card>
                    <Card.Header>
                        <Card.Title>Metodo IQM (3/3)</Card.Title>
								<Card.Subtitle>Il video mostra le correlazioni e fornisce approfondimenti sul tema della disgregazione</Card.Subtitle>
                    </Card.Header>
                    <Card.Body><iframe width="560" height="315" src="https://www.youtube.com/embed/gcL5RWLaZCA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Card.Body>
						<Card.Footer>
							Relatore: Ing. Alessandro De Maria
						</Card.Footer>
                </Card>
                </Col>
                }
                {
                    isLogged(user) && <React.Fragment><Card>
                        <Card.Body>
                            <Card.Title>Inizia a creare schede di valutazione</Card.Title>
                            <FontAwesomeIcon size="5x" icon={faClipboardList} />

                        </Card.Body>
                        <Card.Footer>
                            <ButtonGroup>
                                <Button variant="outline-danger" onClick={() => history.push("/forms/editor/new")} >Nuova Scheda</Button>
                                <Button variant="outline-danger" onClick={() => history.push("/forms")} >Sfoglia Schede</Button>
                            </ButtonGroup>
                        </Card.Footer>

                    </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>Consulta la manualistica</Card.Title>
                                <FontAwesomeIcon size="5x" icon={faBookOpen} />
                            </Card.Body>
                            <Card.Footer>
                                <ButtonGroup>
                                    <Button as="a" variant="outline-danger" download href="pdf/Manuale_IQMIndex.pdf" >Manuale dell'Applicazione</Button>
                                    <Button as="a" variant="outline-danger" download href="pdf/Guida_IQM.pdf" >Guida al metodo IQM</Button>
                                </ButtonGroup>
                            </Card.Footer>
                        </Card>
                    </React.Fragment>

                }

            </Row>





        </React.Fragment >

    )
}
