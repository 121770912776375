import React from "react"

export default function Terms(){
    return(<div>
            <p align="left">
    <strong>IQM Index</strong>
    - App per il calcolo dell'Indice di Qualità Muraria
</p>
<p align="left">
    www.iqmindex.com
</p>
<p align="left">
    
    <strong>TERMINI D'USO DELLA APP</strong>
</p>
<p align="left">
    <em>Oltre al contratto di licenza, a parte viene fornita dal sito l'informativa su Privacy policy e Cookie policy</em>
</p>
<p align="left">
    <strong>Contratto di licenza d'uso della App IQMindex</strong>
</p>
<p align="left">
    In vigore dal giorno 15 febbraio 2021 (inizio di disponibilità della app)
</p>
<p align="left">
    <strong>Licenza d'uso</strong>
</p>
<p align="left">
    La presente App "IQMindex" (nel seguito: IQMindex, univocamente
    identificata dall'indirizzo web: iqmindex.com) è finalizzata a consentire
    al Professionista (nel seguito: Utente) la stima della capacità resistente
    di materiali murari per le proprie elaborazioni su edifici esistenti,
    attraverso la creazione di schede riassuntive costituenti un database
    personale. Gli Amministratori della App possono accedere ai dati archiviati
    per utilizzarli in forma impersonale e aggregata e nel rispetto delle
    normative vigenti sulla privacy e dei codici deontologici professionali.
</p>
<p align="left">
    IQMindex implementa il metodo dell'Indice di Qualità Muraria proposto da
    Antonio Borri e Alessandro De Maria ed illustrato in dettaglio nelle
    seguenti pubblicazioni di riferimento:
</p>
<ul>
    <li>
        <p align="left">
            A. BORRI, A. DE MARIA, (2009) “L’indice di Qualità Muraria (IQM):
            evoluzione ed applicazione nell’ambito delle norme tecniche per le
            costruzioni del 2008” – XIII Convegno ANIDIS "L' ingegneria Sismica
            in Italia", Bologna, Palazzo "Re Enzo", 28 giugno - 2 luglio 2009.
        </p>
    </li>
    <li>
        <p align="left">
            A. BORRI, A. DE MARIA, (2015) “Indice di Qualità Muraria (IQM):
            Correlazione con le caratteristiche meccaniche e livelli di
            conoscenza” – 16° convegno Associazione Nazionale Italiana di
            Ingegneria Sismica, L'Aquila 13-17 settembre 2015.
        </p>
    </li>
    <li>
        <p align="left">
            A. BORRI, G. CASTORI, M. CORRADI, A. DE MARIA, (2015) “A method for
            the analysis and classification of historic masonry” – Bullettin of
            Earthquake Engineering, Vol. 13 (2015), pp. 2647–2665.
        </p>
    </li>
    <li>
        <p align="left">
            A. BORRI, A. DE MARIA, (2015) “Indice di Qualità Muraria (IQM):
            correlazione con le caratteristiche meccaniche e livelli di
            conoscenza” - Progettazione Sismica – Vol. 6, N. 2.
        </p>
    </li>
    <li>
        <p align="left">
            A. BORRI, A. DE MARIA, (2019) “QUALITÀ MURARIA SECONDO IL METODO
            IQM: aggiornamento alla Circolare esplicativa n. 7 del 2019” -
            Structural 222 – Ed De Lettera.
        </p>
    </li>
    <li>
        <p align="left">
            A. BORRI, A. DE MARIA, (2020) “L’INDICE DI QUALITÀ MURARIA (IQM) e
            la disgregazione delle murature per effetto del sisma” - Structural
            229 – Ed De Lettera.
        </p>
    </li>
    <li>
        <p align="left">
            A. BORRI, M. CORRADI, A. DE MARIA, (2020) “The Failure of Masonry
            Walls by Disaggregation and the Masonry Quality Index” - Heritage
            2020, 3(4), 1162-1198;
            <a href="https://doi.org/10.3390/heritage3040065">
                https://doi.org/10.3390/heritage3040065
            </a>
            - 22 Oct. 2020
        </p>
    </li>
</ul>
<p>
    Il metodo è stato progressivamente aggiornato secondo le evoluzioni delle
    funzionalità e delle Normative tecniche
</p>
<p align="left">
    La App IQMindex è stata sviluppata da AEDES Software (Via Ferrante Aporti
    32, 56028 San Miniato (PI), P.IVA/C.F. 01735120972, Legale rappresentante:
    Francesco Pugi), nel seguito: AEDES, proprietaria del dominio iqmindex.com.
</p>
<p align="left">
    AEDES ha istituito un accordo di collaborazione con un 'Gruppo di Lavoro'
    di cui fanno parte gli autori del metodo IQM. Il Gruppo di Lavoro svolgerà
    un ruolo di supervisione delle attività previste nel presente contratto tra
    AEDES ed i singoli utenti potendo, in cambio, utilizzare le informazioni
    per finalità di ricerca nei modi specificati nel seguito.
</p>
<p align="left">
    <strong>1. Introduzione</strong>
</p>
<p align="left">
    <br/>
    <strong>1.1. Contratto</strong>
</p>
<p align="left">
    Cliccando su “Iscriviti a IQMindex”, registrandosi, accedendo o utilizzando
    i nostri servizi (descritti di seguito), Lei accetta i termini del presente
    Contratto (“Contratto” o “Contratto di licenza”). Qualora decida di non
    accettarli, NON clicchi su “Iscriviti ora” (o simile) e non acceda o
    utilizzi in altro modo nessuno dei nostri Servizi. Qualora Lei voglia
    risolvere il presente Contratto, potrà farlo in qualsiasi momento chiudendo
    il Suo account e smettendo di accedere ai nostri Servizi o utilizzarli.
</p>
<p align="left">
    <strong>Servizi</strong>
</p>
<p align="left">
    Il presente Contratto si applica a IQMindex.com, alla app IQMindex e ad
    altri siti, app, comunicazioni e servizi correlati a IQMindex
    esplicitamente offerti in virtù del presente Contratto (“Servizi”),
    includendo la raccolta di dati per l'utilizzo nelle modalità nel seguito
    specificate. Gli Utenti registrati ai nostri Servizi sono suddivisi in
    quattro gruppi: Semplice, Avanzato, Amministratore o Supervisore, come
    descritto in dettaglio al punto 2.3.
</p>
<p align="left">
    <strong>IQMindex</strong>
</p>
<p align="left">
    Lei sta per stipulare questo Contratto con gli autori della app (al quale
    si fa riferimento anche come “noi”).
</p>
<p align="left">
    La raccolta e l’utilizzo dei Suoi dati personali sono soggetti
    all'Informativa sulla privacy e all'Informativa sui cookie entrambe
    reperibili in iqmindex.com.
</p>
<p align="left">
    <strong>1.2. Utenti</strong>
</p>
<p align="left">
    Quando si registra e si iscrive ai Servizi IQMindex, diventa un Utente. Se
    ha scelto di non registrarsi per i nostri Servizi, può accedere ad alcune
    funzionalità in qualità di semplice visitatore del sito, ad esempio per
    consultare la documentazione in chiaro riguardante la Teoria di IQMindex.
</p>
<p align="left">
    <strong>1.3. Modifica</strong>
</p>
<p align="left">
    Potremmo di volta in volta modificare questo Contratto, la nostra
    Informativa sulla privacy e la nostra Informativa sui cookie. Nel caso in
    cui apportassimo modifiche sostanziali, La informeremo tramite i nostri
    Servizi, o altri mezzi, per darLe l’opportunità di conoscere le modifiche.
    Se disapprova le modifiche, può chiudere il Suo account. Se dopo la
    pubblicazione o l’invio di un avviso relativo alle modifiche apportate ai
    presenti termini e condizioni continuerà a utilizzare i nostri Servizi,
    riterremo da Lei accettati i termini e le condizioni aggiornati a partire
    dalla data in cui sono entrati in vigore.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>2. Obblighi</strong>
</p>
<p align="left">
    <strong>2.1. Idoneità al servizio</strong>
</p>
<p align="left">
    Per utilizzare i Servizi, Lei accetta che: (1) deve avere un solo account
    IQMindex, che dovrà corrispondere al Suo vero nome; (2) e il Suo utilizzo
    dei Servizi non è mai stato sospeso da IQMindex. La creazione di un account
    con informazioni false rappresenta una violazione dei nostri termini e
    condizioni.
</p>
<p align="left">
    <strong>2.2. Il Suo account</strong>
</p>
<p align="left">
    Gli Utenti sono titolari di un account. Lei accetta di: (1) usare una
    password robusta e di tenerla segreta; (2) non trasferire alcuna parte del
    Suo account e (3) rispettare la legge e le condizioni di utilizzo di cui al
    punto 2.3.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>3. Condizioni di utilizzo</strong>
</p>
<p align="left">
    I servizi offerti da IQMindex sono a titolo gratuito: nessun pagamento è
    richiesto per l'utilizzo della app. Lei riconosce che esistono quattro
    livelli di Utenti, di seguito elencati insieme alle corrispondenti attività
    consentite (per 'scheda' si intende il prodotto completo di elaborazione
    della singola sessione per il calcolo dell'indice di qualità muraria e dei
    corrispondenti parametri meccanici):
    <br/>
    • <strong>Utente Semplice</strong> :
</p>
<p align="left">
    - Può salvare e stampare un massimo di 10 schede<br/>
    - Può scaricare le schede in formato PDF<br/>
    - Può visualizzare, copiare, modificare ed eliminare le sue schede.<br/>
    - Può visualizzare il suo livello di privilegio<br/>
    - Può modificare la sua password<br/>
    - Può eliminare il suo account
</p>
<p align="left">
    • <strong>Utente Avanzato</strong> :
</p>
<p align="left">
    - Può salvare e stampare un massimo di 50 schede<br/>
    - Può scaricare le sue schede in formato PDF<br/>
    - Può visualizzare, copiare, modificare ed eliminare le sue schede<br/>
    - Può visualizzare il suo livello di privilegio<br/>
    - Può modificare la sua password<br/>
    - Può eliminare il suo account
</p>
<p align="left">
    • <strong>Utente Amministratore</strong> :
</p>
<p align="left">
    - Può salvare e stampare schede senza limiti di quantità<br/>
    - Può scaricare in formato PDF le schede sue e di altri utenti<br/>
    - Può visualizzare, copiare ed eliminare le sue schede e quelle di altri
    utenti, può modificare ed eliminare le sue schede<br/>
    - Può visualizzare e modificare la password e il livello di privilegio
    degli altri utenti<br/>
    - Può visualizzare ed eliminare gli utenti iscritti
</p>
<p align="left">
    • <strong>Utente Supervisore</strong> (i componenti del Gruppo di Lavoro):
</p>
<p align="left">
    - Può salvare schede senza limiti di quantità<br/>
    - Può scaricare in formato PDF le schede sue e di altri utenti<br/>
    - Può visualizzare e copiare le sue schede e quelle di altri utenti, 
    può modificare ed eliminare le sue schede <br/>
    - Può visualizzare il suo livello di privilegio<br/>
    - Può modificare la sua password<br/>
    - Può eliminare il suo account<br />
    </p>

<p align="left">
    E' Utente Amministratore un Autore della app.<br />
    E' Utente Supervisore un componente del Gruppo di Lavoro citato al
    paragrafo introduttivo di questo contratto.
    <br/>
    E' Utente Semplice un qualsiasi Utente che, dopo aver creato un proprio
    account, utilizza la app.
    <br/>
    E' Utente Avanzato un Utente semplice che usufruisca di uno dei seguenti
    titoli:
    <br/>
    - è un cliente di AEDES con servizio di supporto in corso di validità nella
    data di utilizzo della app;
    <br/>
    - è un Utente che, dietro sua richiesta, viene autorizzato da un Utente 
    Amministratore attraverso accordi specifici, 
    ad esempio per scopi di ricerca e di sviluppo della App stessa.
</p>
<p align="left">
    L'Utente autorizza IQMindex a conservare i propri dati senza impegno.
    L'Utente, dopo aver elaborato nella singola sessione i calcoli consentiti
    dalla app, può stampare la scheda e quindi usufruirà dei risultati
    indipendentemente dalla successiva conservazione: IQMindex non assume
    impegni nei confronti della possibilità di reperire in ogni momento e/o a
    tempo indeterminato le proprie schede.
</p>
<p align="left">
    L'Utente sia semplice sia avanzato autorizza ciascun Utente Amministratore
    ed Utente Supervisore all'utilizzo delle elaborazioni delle proprie schede
    in formato anonimo, con finalità di ricerca scientifica e di divulgazione
    attraverso pubblicazioni sia web sia editoriali cartacee, convegni e/o
    corsi professionali aventi per argomento casistiche di materiali murari,
    anche elaborando statistiche in formato aggregato, rispettando in ogni caso
    la non riconoscibilità della struttura originaria e dell'Utente
    corrispondente, eccetto la localizzazione geografica la cui scala è a
    livello di Codice di Avviamento Postale (CAP) e non a maggior dettaglio.
    Nell'ambito di tale attività di ricerca scientifica e di divulgazione,
    l'Utente Amministratore o l’Utente Supervisore potranno utilizzare i dati,
    le immagini e i disegni caricati dall'Utente (semplice o avanzato) nella
    app senza alcun permesso specifico o liberatoria da parte di tale Utente
    e/o dell’eventuale terzo autore di contenuti (dati, immagini e disegni)
    caricati da tale Utente nella app: la responsabilità del rispetto dei
    diritti sui contenuti caricati da tale Utente resta a completo carico
    dell'Utente stesso, il quale accettando il presente Contratto di licenza
    d'uso solleva IQMindex, AEDES e gli Utenti Amministratori e Supervisori da
    qualunque responsabilità riguardante i diritti su tali contenuti.
</p>
<p align="left">
    I Servizi di IQMindex non prevedono la gestione di contatti fra Utenti
    distinti: ogni Utente semplice o avanzato utilizza IQMindex allo scopo di
    elaborazione delle proprie schede senza alcun accesso ai dati di altri
    Utenti semplici o avanzati. L'accesso ai dati elaborati da ogni altro
    Utente è consentito esclusivamente ad un Utente Amministratore.
</p>
<p align="left">
    Il software della app è di proprietà dell'Autore del software stesso
    (AEDES) ed è tutelato dalle leggi sul copyright vigenti, dalle disposizioni
    dei trattati internazionali e da tutte le altre leggi nazionali
    applicabili, come ogni altro materiale coperto da copyright. Nessun Utente
    potrà convertire, decodificare, decompilare o disassemblare il software. La
    licenza d’uso del software non comprende la facoltà di ottenerlo in formato
    sorgente, né di disporre della relativa documentazione logica e/o di
    progetto.
</p>
<p align="left">
    <strong>2.4. Supporto tecnico</strong>
</p>
<p align="left">
    Lei accetta che l'utilizzo di IQMindex non dà diritto ad alcun supporto
    tecnico. La documentazione teorica della app, prodotta dagli autori del
    software e dai Supervisori, è disponibile nell'ambito della Guida all'uso
    della app stessa, e Lei riconosce che la app trova supporto esclusivamente
    nella documentazione della Guida, per quanto riguarda i contenuti sia
    teorici sia di tipo informatico quali la descrizione dei comandi d'uso.
</p>
<p align="left">
    <strong>2.5. Avvisi e messaggi</strong>
</p>
<p align="left">
    Lei accetta che IQMindex e AEDES possono inviarLe avvisi e messaggi al
    recapito che ci ha fornito (indirizzo email) all'atto di creazione del Suo
    account. Se non desidera più ricevere informazioni da IQMindex e da AEDES,
    potrà in ogni momento richiedere la rimozione del Suo account rinunciando
    ai servizi di IQMindex. Le e-mail registrate vengono utilizzate da IQMindex
    e da AEDES unicamente al fine di informative relativi ai propri servizi, ed
    in nessun caso vengono condivise con altri operatori né cedute ad alcun
    titolo a terzi.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>3. Diritti e limiti</strong>
</p>
<p align="left">
    <br/>
    <strong>3.1. La Sua Licenza a IQMindex</strong>
</p>
<p align="left">
    Lei detiene la proprietà di tutti i contenuti e le informazioni contenute
    nelle schede personali, ma concede a IQMindex anche una licenza non
    esclusiva per gli stessi con finalità delle elaborazioni di Ricerca
    indicate al punto 2.3.
</p>
<p align="left">
    Lei accetta ed assume la responsabilità di fornire solo contenuti o
    informazioni che non violano la legge o i diritti di terzi (includendo i
    diritti di proprietà intellettuale), ad esempio per quanto riguarda le
    immagini caricate nelle schede da Lei elaborate, declinando ogni
    responsabilità in tal senso a IQMindex.
</p>
<p align="left">
    <strong>3.2. Disponibilità dei Servizi</strong>
</p>
<p align="left">
    Noi possiamo cambiare, sospendere o interrompere qualsiasi nostro Servizio.
    Noi non possiamo garantire di conservare o continuare a mostrare le
    informazioni e i contenuti che Lei ha archiviato. IQMindex non è un
    servizio di archiviazione. Lei accetta che non esista per noi l’obbligo di
    archiviare, gestire o fornirLe una copia di contenuti o informazioni da Lei
    forniti. In caso di disiscrizione da IQMindex, ossia di rimozione del Suo
    account, IQMindex si impegna a non gestire ulteriormente i dati delle
    schede da Lei elaborate: tale impegno non ha tuttavia contenuto
    retroattivo, e quindi Lei accetta che i dati delle Sue schede con le
    finalità di elaborazione di cui al punto 2.3 possano essere stati
    utilizzati, nelle modalità regolate dal presente Contratto, in date
    antecedenti la Sua disiscrizione.
</p>
<p align="left">
    <strong>3.3. Altri contenuti, siti e applicazioni</strong>
</p>
<p>
    L’utilizzo da parte Sua dei contenuti delle schede elaborate è a Sua
    responsabilità. IQMindex non ha obbligo di esame, controllo e/o verifica
    di'idoneità nei confronti dei i contenuti forniti dai propri Utenti e delle
    schede da loro elaborate. Lei accetta che non rispondiamo per i contenuti
    che violano la legge, incluso qualsiasi tipo di copyright. Non possiamo
    sempre prevenire l’utilizzo inadeguato dei nostri Servizi e Lei riconosce
    che non rispondiamo per tale utilizzo.
</p>
<p align="left">
    <strong>3.4. Limiti</strong>
</p>
<p align="left">
    IQMindex si riserva il diritto di limitare, sospendere o chiudere il Suo
    account nel caso in cui Lei stia violando il presente Contratto o la legge,
    ovvero che stia utilizzando i Servizi in modo improprio.
</p>
<p align="left">
    <strong>3.5. Diritti di proprietà intellettuale</strong>
</p>
<p align="left">
    IQMindex si riserva tutti i suoi diritti di proprietà intellettuale nei
    Servizi. I marchi e i loghi utilizzati in relazione ai Servizi sono marchi
    dei rispettivi proprietari. IQMindex, i propri loghi e tutti i contenuti
    della documentazione a corredo della app sono di proprietà degli autori di
    IQMindex.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>4. Limitazione di responsabilità</strong>
</p>
<p align="left">
    <strong>4.1. Assenza di garanzia</strong>
</p>
<p align="left">
    Nonostante ogni sforzo sia stato compiuto sia per implementare la corretta
    procedura di calcolo, secondo la Teoria di riferimento dell'Indice di
    Qualità Muraria, sia per fornire all'Utente la guida all'utilizzo corretto
    della app attraverso la documentazione resa disponibile su iqmindex.com,
    IQMindex non si assume alcuna responsabilità né garantisce per i servizi,
    inclusa la garanzia che i servizi saranno disponibili per sempre ed esenti
    da errori, e forniscono i servizi (inclusi i contenuti e le informazioni)
    “così come sono” e “secondo disponibilità”. Per quanto consentito dalla
    legge applicabile, IQMindex non riconosce alcuna garanzia implicita o
    legale, comprese l'assenza di violazioni e l'idoneità per uno scopo
    specifico.
</p>
<p align="left">
    <strong>4.2. Esclusione di responsabilità</strong>
</p>
<p align="left">
    Per quanto consentito dalla legge, IQMindex non risponderà in alcun modo
    per conseguenze legate ad uso improprio delle elaborazioni fornite dai
    servizi.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>5. Risoluzione e controversie</strong>
</p>
<p align="left">
    <strong>5.1. Risoluzione del contratto</strong>
</p>
<p align="left">
    Lei e IQMindex potete risolvere questo Contratto in qualsiasi momento
    attraverso la disiscrizione ossia la chiusura del Suo account. In caso di
    risoluzione, Lei perde il diritto di accedere alla app o utilizzare i
    Servizi.
</p>
<p align="left">
    <strong>
        5.2. Legislazione applicabile e risoluzione delle controversie
    </strong>
</p>
<p align="left">
    In caso di controversia circa l’interpretazione e l’esecuzione del presente
    contratto, Foro esclusivamente competente è quello di Pisa.
    <br/>
    <br/>
</p>
<p align="left">
    <strong>6. Come contattarci</strong>
</p>
<p align="left">
    Per qualsiasi comunicazione Lei può contattarci all'indirizzo email
    specificato sul sito iqmindex.com.
</p>

         </div>)
}