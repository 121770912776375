import React from "react"
import { Alert } from "react-bootstrap"

export default function Feedback({ feedback }) {
    const [showFeedback, setShowFeedback] = React.useState(false)
    React.useEffect(() => {
        if (feedback) {
            setShowFeedback(true)
            const timer = setTimeout(() => setShowFeedback(false), 5000)
            return () => clearTimeout(timer);
        }
    }, [feedback])

    return (<Alert
        show={showFeedback}
        className="sticky-top"
        onClose={() => setShowFeedback(false)}
        dismissible
        variant={feedback ? feedback.variant : "success"} >
        {feedback ? feedback.message : ""}
    </Alert>);
}