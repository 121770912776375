import { useState, useEffect } from "react";
import React from "react";
import { Spinner } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { isLogged } from "../../../auth"
import axios from "axios"
import Form from "./Form";
import { UserContext } from "../../UserContext"


export default function FormEditor(props) {
    const { setFeedback, user } = React.useContext(UserContext);
    const [form, setForm] = useState(null);

    useEffect(() => {
        async function getForm() {
            if(!user)
                throw Error("Non autorizzato");
            var _form = {
                author: user.email
            };
            if (props.location.search) {
                const id = props.location.search.substr(1, props.location.search.length);

                if (id) {
                    const result = await axios.get("/api/forms/view", { params: { id: id, action: props.match.params.action } })
                    if (result.error) throw Error(result.error);
                    _form = result.data;
                }
            }
            setForm(_form);

        }

            getForm().catch((error) => setFeedback({ variant: "danger", message: error.message }));
    }, [props.location.search, props.match.params.action, setFeedback, user]);





    return (
        !isLogged(user) ?
            <Redirect to="/login" /> : form ? <Form defaultValues={form} action={props.match.params.action} /> : <Spinner size="lg" animation="grow" />
    );
}
