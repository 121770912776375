import React, { useContext } from "react"
import {Route, Redirect } from "react-router-dom"
import { isLogged } from "./auth";
import {UserContext} from "./components/UserContext"

export default function AnonymousRoute({children, exact, path,component}){
    const {user} = useContext(UserContext);

    return(
        !isLogged(user) ?
        <Route exact={exact} path={path} component={component}>
            {children}
        </Route> :
        <React.Fragment>
            <Redirect  to="/"/>
        </React.Fragment>
    )
}
