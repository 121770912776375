import React from "react";
import { categories } from "../utils"

export default function TableCell({ value, type }) {
    const format = (value, type) => {
        switch (type) {
            case "date":
                return new Date(value).toLocaleDateString('it-IT');
            case "select":
                return categories[value]
            default:
                return value;
        }

    }
    return (
        <td key={value+"-td"}>
            {format(value, type)}
        </td>
    )
}

