import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import FormFileInput from "./FormFileInput"
import { Form, Col, Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import PreviewList from "./PreviewList";

export default function SchemaChooser(props) {
    const { watch, setValue } = useFormContext();
    const [list, setList] = useState([]);
    const [index, setIndex] = useState(-1);
    const currentValue = watch("diagrams." + props.direction, props.defaultValue);
    
    useEffect(() => {

        const changeDiagram = () => {
            var newDiagram;
            if (index === -1)
                newDiagram = null;
            else
                newDiagram = list[index];

            setValue("diagrams." + props.direction, newDiagram, { shouldDirty: true, shouldValidate: true });
        }
        changeDiagram();

    }, [index, list, props.direction, setValue]);

    useEffect(() => {

        async function getList() {
            const url = "/api/resources/defaultlist";
            return axios.get(url, {
                params: {
                    kind: "diagram",
                    direction: props.direction,
                    perPage: -1
                },
            });
        }
        getList().then(res => {
           setList(res.data);
            if (props.defaultValue) {
                setIndex(res.data.findIndex((elem) => {
                    return elem === props.defaultValue;
                }));
                if(props.defaultValue){
                    setValue("diagrams." + props.direction, props.defaultValue,{ shouldDirty: true, shouldValidate: true }  )
                }
            }
        }).catch(error => console.log(error));
    }, [props.defaultValue, props.direction, setValue]);

    return (
        <React.Fragment>
            <Col><Form.Label>{props.directionName}</Form.Label><br/>
                
                {index !== -1 && <Button variant = "outline-danger"
                    size="sm"
                    onClick={() => { setIndex(index - 1) }}
                >
                    Indietro
                </Button>}
                {((index === -1) && (currentValue === null)) &&
                    <Button size="sm" variant = "outline-danger"
                        onClick={() => { setIndex(index + 1) }}>
                        Sfoglia Schemi
                    </Button>
                }
                {((index !== -1) && (index !== list.length - 1)) &&
                    <Button size="sm" variant = "outline-danger"
                        onClick={() => { setIndex(index + 1) }}>
                        Avanti
                    </Button>
                }
                {(index !== -1) &&
                    <Button variant = "danger" size="sm" 
                        onClick={() => { setIndex(-1) }}>Rimuovi
                    </Button>
                }
                
                    < FormFileInput 
                        hidden = {index !== -1 || currentValue !== null}
                        name={"diagrams." + props.direction} 
                        multiple={false} 
                        showLabel={false} 
                        label="Schema" 
                    />
                <PreviewList toUpload={index === -1 && !list.includes(currentValue)} multiple={false} watched={"diagrams." + props.direction} />
            </Col>
        </React.Fragment>
    );
}
