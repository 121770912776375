import React from "react";
import { Table } from "react-bootstrap"

export default function GeometryForm({ defaultValue = null }) {

    return (
        <React.Fragment>
            <h5>Dimensioni ricorrenti degli elementi (in centimetri)</h5>
            <Table responsive>
                <thead>
                    <tr>
                        <th/>
                        <th >Altezza</th>
                        <th >Lunghezza</th>
                        <th >Profondità</th>
                    </tr>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Min</td>
                        <td> {defaultValue ? defaultValue.h.min : null}</td>
                        <td> {defaultValue ? defaultValue.l.min : null}</td>
                        <td> {defaultValue ? defaultValue.p.min : null}</td>
                        </tr>
                        
                        <tr><td>Max</td>
                        <td>{defaultValue ? defaultValue.h.max : null}</td>
                        <td>{defaultValue ? defaultValue.l.max : null}</td> 
                        <td>{defaultValue ? defaultValue.p.max : null}</td>
                    </tr>
                </tbody>
               
            </Table>
        </React.Fragment>

    )
}
