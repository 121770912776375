import React, { useState } from "react"
import { UserContext } from "./UserContext";
import { isAdmin } from "../auth"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';

registerLocale('it', it);

export default function TableHeader({ header, onChange, index }) {
    const [startDate, setStartDate] = useState(new Date());
    const { user } = React.useContext(UserContext);
    return (
        (!header.admin || isAdmin(user)) &&
        <th key={index + "th"}>{header.name}<br />
            {chooseHeader(header, onChange, index, { startDate, setStartDate })}
        </th>)
}

export function InputHeader({ header, onChange, index }) {
    return (<input
        key={index + "-input"}
        id={"filter-" + header.accessor}
        onChange={(event) => {
            event.preventDefault();
            onChange(header.accessor, event.target.value)
        }}
        placeholder={"Cerca " + header.name}
        type="text" />)
}

export function DateHeader({ header, onChange, index, date, setStartDate }) {
    const [before, setBefore] = useState(true)
    const [mostRecent, setMostRecent] = useState(true)
    const handleChange = (event) => {
        if (event.target.name === "before") {
            setBefore(event.target.value === "before")
            onChange("before", event.target.value === "before")
        } else if (event.target.name === "mostRecent") {
            setMostRecent(event.target.value === "mostRecent")
            onChange("mostRecent", event.target.value === "mostRecent")
        }
    }
    return (<React.Fragment>
        <div>
            <label>
                <input type="radio"
                    name="mostRecent"
                    value="mostRecent"
                    key={index + "-date.mostRecent"}
                    id={"filter-" + header.accessor + ".mostRecent"}
                    checked={mostRecent}
                    onChange={handleChange}
                />Dalla più recente
            </label><br />
            <label>
                <input type="radio"
                    name="mostRecent"
                    value="lessRecent"
                    key={index + "-date.after"}
                    id={"filter-" + header.accessor + ".mostRecent"}
                    checked={!mostRecent}
                    onChange={handleChange}
                />Dalla meno recente
            </label>

        </div>
        <div>
            <label>
                <input type="radio"
                    name="before"
                    value="before"
                    key={index + "-date.before"}
                    id={"filter-" + header.accessor + ".before"}
                    checked={before}
                    onChange={handleChange}
                />
                Prima del:
            </label><br />
            <label>
                <input type="radio"
                    name="before"
                    value="after"
                    key={index + "-date.after"}
                    id={"filter-" + header.accessor + ".before"}
                    checked={!before}
                    onChange={handleChange}
                />Dopo il:
            </label>
        </div>
        <DatePicker
            onChange={(date) => {
                onChange("date", date)
            }}
            locale="it"
            dateFormat="dd/MM/yyyy"
            key={index + "-date"}
            id={"filter-" + header.accessor}
            selected={date}
            onSelect={date => setStartDate(date)}
        />
    </React.Fragment>)
}

export function SelectHeader({ header, onChange, index }) {
    return (
        <select
            key={index + "-date"}
            id={"filter-" + header.accessor}
            onChange={(event) => {
                event.preventDefault();
                onChange(header.accessor, event.target.value)
            }}>
            {header.datalist.map((value, index) => (
                <option value={index}>
                    {value}
                </option>
            ))}
        </select>
    )
}

const chooseHeader = (header, onChange, index, date) => {
    switch (header.type) {
        case "select":
            return (<SelectHeader
                header={header}
                onChange={onChange}
                index={index}
            />)
        case "date":
            return (<DateHeader
                header={header}
                date={date.startDate}
                setStartDate={date.setStartDate}
                onChange={onChange}
                index={index}
            />)
        default:
            return (<InputHeader
                header={header}
                onChange={onChange}
                index={index} />)
    }
}