import React, { useState, useEffect, useContext, useRef } from "react";
import { Card, Spinner, FormCheck, Button, Badge, ButtonGroup, Container, InputGroup, Form } from "react-bootstrap";
import { isAdmin, doLogout } from "../../../auth"
import { updateUserSchema } from "../../../validation"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserContext } from "../../UserContext";
import ReCAPTCHA from "react-google-recaptcha"

export default function UserViewer(props) {
    var history = useHistory();
    const { user, setUser, setFeedback } = useContext(UserContext);
    const [viewedUser, setViewedUser] = useState(null);
    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(updateUserSchema) });
    const [showModifyPassword, setShowModifyPassword] = useState(false);
    const [showModifyPrivileges, setShowModifyPrivileges] = useState(false);
    const reRef = useRef();

    useEffect(() => {
        function fetchData() {
            let id = "";
            if(props.location.search && isAdmin(user,true))
                id = props.location.search.substr(1, props.location.search.length);
            else
                id = user._id;

            axios.get("/api/users/view", { params: { id: id } })
                .then((result) => {
                    setViewedUser(result.data);
                }).catch(error => setFeedback({ variant: "danger", message: "Si è verificato un errore" }));


        } fetchData()
    }, [props.location.search, setFeedback, user]);

    const deleteUser = async () => {
        try {

            const recaptcha = await reRef.current.executeAsync();
            reRef.current.reset();
            const res = await axios.delete("/api/users/", { params: { id: viewedUser._id, recaptcha: recaptcha } })

            if (res.status < 400) {
                setFeedback({ variant: "success", message: "Cancellazione riuscita" });
                if (isAdmin(user)) history.push({ pathname: "/users" })
                else doLogout(setUser);
            } else {
                throw Error();
            }

        } catch (error) {
            setFeedback({ variant: "danger", message: "Si è verificato un errore" })
        };
    }


    const updateUser = async (data) => {
        try {
            const recaptcha = await reRef.current.executeAsync();
            reRef.current.reset();
            const res = await axios({ method: "patch", url: "/api/users/", data: { update: {...data, _id : viewedUser._id} , recaptcha: recaptcha }})
            if (res.status < 400){
                setViewedUser(res.data);
                setFeedback({ variant: "success", message: "Aggiornamento riuscito" });
            }
            else throw new Error();
        } catch (error) {
            setFeedback({ variant: "danger", message: "Si è verificato un errore" })
        }
    }

    return (
        viewedUser ?
            <Container>
                <Card>
                    <Form onSubmit={handleSubmit(updateUser)}>
                        <Card.Header>

                            <Card.Title>
                                <h3>Email: {viewedUser.email}</h3>
                                <h5>Privilegi: {viewedUser.privileges} </h5>
                            </Card.Title>

                            <ButtonGroup>

                                {((!isAdmin(user,true) && viewedUser.email === user.email) || (
                                    isAdmin(user,true) &&
                                    user.email !== viewedUser.email)) &&
                                    <Button variant = "danger" onClick={deleteUser}>Elimina</Button>
                                }
                                {(showModifyPrivileges || showModifyPassword) && <Button variant = "outline-danger" type="submit" >Salva</Button>}
                            </ButtonGroup>

                        </Card.Header>
                        <Card.Body>
                        <Form.Group>
                                <Form.Label>Password Corrente</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text >Password</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        ref={register}
                                        name="oldPassword"
                                        type="password"
                                    />
                                </InputGroup>{errors.oldPassword && <Badge>{errors.oldPassword.message}</Badge>}
                            
                           </Form.Group> 
                           {isAdmin(user, true) &&<React.Fragment>
                           <Form.Group>
                               <FormCheck name="modifyPrivileges" label="Modifica privilegi" defaultValue = {showModifyPrivileges} onChange={() => setShowModifyPrivileges(!showModifyPrivileges)} ref={register} />
                           </Form.Group> 
                           <Form.Group  hidden = {!showModifyPrivileges} >
                               <Form.Label>Privilegi</Form.Label>
                                
                                <Form.Control as="select" ref={register} name="privileges" defaultValue={viewedUser.privileges} type="select" >
                                    <option>ADMIN</option> 
                                    <option>SUPERVISOR</option>
                                    <option>ADVANCED</option>
                                    <option>SIMPLE</option>
                                </Form.Control>
                                {errors.privileges && <Badge>{errors.privileges.message}</Badge>}
                            </Form.Group>
                            </React.Fragment>}
                            <React.Fragment>
                           <Form.Group>
                               <FormCheck  name="modifyPassword" label="Modifica password" defaultValue = {showModifyPassword} onChange={() => setShowModifyPassword(!showModifyPassword)} ref={register} />
                           </Form.Group> 
                           <Form.Group hidden={!showModifyPassword}>
                                <Form.Label>Modifica Password</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text >Nuova Password</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        ref={register}
                                        name="password"
                                        type="password"
                                    />
                                </InputGroup>

                                {errors.password && <Badge>{errors.password.message}</Badge>}
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text >Conferma Password</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        ref={register}
                                        name="confirmPassword"
                                        type="password"
                                    />
                                </InputGroup>
                                {errors.confirmPassword && <Badge>{errors.confirmPassword.message}</Badge>}
                            </Form.Group>
                            </React.Fragment>

                        </Card.Body>
                    </Form>
                </Card>
                <ReCAPTCHA size="invisible" ref={reRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />
            </Container> : <Spinner size="lg" animation="grow" />
    );
}
