import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form"
import { InputGroup, Form, FormControl, Button, ButtonGroup } from "react-bootstrap"

export default function MaterialList({ defaultValue }) {


  const [indexes, setIndexes] = React.useState([]);

  const [counter, setCounter] = React.useState(0);
  const [useDefault, setUseDefault] = React.useState(!!defaultValue);
  const { register } = useFormContext();

  useEffect(() => {

    if (defaultValue) {
      setUseDefault(true);
      defaultValue.forEach(() => {
        setIndexes([...Array(defaultValue.length).keys()]);
        setCounter(defaultValue.length);
      });
    } else {
      setUseDefault(false);
    }


  }, [defaultValue])

  const addMaterial = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
    setUseDefault(false);
  };

  const removeMaterial = index => () => {

    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setCounter(prevCounter => prevCounter - 1);
    setUseDefault(false);
  };

  const clearMaterials = () => {
    setIndexes([]);
    setCounter(0);
    setUseDefault(false);
  };

  return (
    <Form.Group>
      <label>Descrizione dei materiali</label><br/>
      {indexes.map(index => {
        const fieldName = `materials[${index}]`;
        return (<InputGroup><InputGroup.Prepend><InputGroup.Text>{index + 1}</InputGroup.Text></InputGroup.Prepend>
          <FormControl
            type="text"
            name={`${fieldName}`}
            ref={register}
            defaultValue={useDefault ? defaultValue[index] ? defaultValue[index] : "" : ""}

          />
          <InputGroup.Append>
            <Button variant="danger" type="button" onClick={removeMaterial(index)}>
              Rimuovi descrizione
              </Button>
          </InputGroup.Append>
        </InputGroup>
        );
      })}
      <ButtonGroup>
        <Button variant = "outline-danger" disabled={counter === 3} onClick={addMaterial}>
          Aggiungi descrizione
        </Button>
        {counter !== 0 && <Button variant="danger"
          onClick={clearMaterials} >
          Elimina tutte le descrizioni
        </Button>
        }
      </ButtonGroup>
    </Form.Group>
  );
}