import React from "react"
import {Pagination} from "react-bootstrap"



export default function BrowserTablePagination(props){
    return(<Pagination>
        <Pagination.Prev onClick = {props.onClick} disabled = {parseInt(props.page) === 1} id = {"filter-page-" + (parseInt(props.page)-1)}/>
        <Pagination.Next onClick = {props.onClick} disabled = {props.end}  id = {"filter-page-" + (parseInt(props.page)+1)}/>
        <select defaultValue = {5} onChange = {props.onClick} id = "filter-perPage">
        <option>5</option>
        <option>10</option>
        <option>20</option>
        <option>30</option>
        </select>
    </Pagination>
    );
}