import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button } from "react-bootstrap"

export default function FormButton (props) {
  const { formState: { isDirty, isSubmitting } } = useFormContext()

  return (
    <Button style={{margin : "1%"}} variant = "outline-danger" type="submit" disabled={!isDirty || isSubmitting}>{props.content}
    </Button>
  )
}
