export const categories = ["altra tipologia","in pietrame disordinata",
                "a conci sbozzati ",
                "a conci sbozzati (con zeppe profonde in pietra)",
                "in pietre a spacco",
                "irregolare di pietra tenera",
                "regolare di pietra tenera",
                "a blocchi lapidei squadrati",
                "in mattoni pieni e malta di calce (giunti <= 13mm)",
                "in mattoni pieni e malta di calce (giunti > 13mm)",
                "in mattoni semipieni con malta cementizia"]

export const limits = {
    ADVANCED: 50,
    SIMPLE: 10
}

export const columns = {
    forms: [
      {
        name: "Autore",
        accessor: "author",
        admin: true
      },
      {
        name: "Nome",
        accessor: "name"
      },
      {
        name: "CAP",
        accessor: "zone"
      },
      {
        name: "Tipologia Muraria",
        accessor: "masonryCategory",
        type: "select",
        datalist: categories
      },
      {
        name: "Data Creazione",
        accessor: "date",
        type: "date"
      }
    ],
  
    users: [
      {
        name: "Email",
        accessor: "email",
        admin: true
      },
      {
        name: "Privilegi",
        accessor: "privileges",
        admin: true
      }
    ]
  
  }
  