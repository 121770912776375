import React from "react";
import axios from "axios";


export const renew = async () => {

    try {
    const response = await axios.get("/api/users/token");
    return response.data;
  } catch (e) { 
    return null;
  }
  }


export const UserContext = React.createContext(
    {
        user: null,
        setUser: () => { },
        analytics: null,
        setAnalytics : () =>{},
        feedback: null,
        setFeedback: () => { }
    });

