import React, { useEffect, useCallback, useState, useContext } from "react";
import BrowserTable from "./BrowserTable";
import BrowserTablePagination from "./BrowserTablePagination";
import { Redirect } from "react-router-dom";
import { isLogged, isAdmin } from "../auth";
import axios from "axios";
import { Container, Spinner, Button } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { columns } from "../utils"

export default function Browser({ type }) {
  const { user, setFeedback } = useContext(UserContext)
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({ page: 1, perPage: 5});
  
  const fetchData = useCallback((download = false) => {
    let params = filter;
    if(download)
      params.download = true 
    axios(
      "/api/" + type, { params: params }
    ).then((result) => {
      if(download){
        return result.data;
      }
        
      setData(result.data[type]);
      if (result.data.count)
        setCount(result.data.count);
        setLimit(result.data.limit);
    }).catch(() => setFeedback({ variant: "danger", message: "Si è verificato un errore, ricarica la pagina per riprovare" }));
  }, [filter, setFeedback, type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const filterChange = (key, value) => {
    console.log(key, value)
    var newFilter = filter;
    newFilter[key] = value;
    Object.keys(newFilter).forEach(key => {
      if (newFilter[key] === undefined || newFilter[key] === "")
        delete newFilter[key];
    })
    newFilter.page = 1;
    setFilter(newFilter);
    fetchData();
  }

  const changePage = (event) => {

    const filterId = event.target.id.split("-")[1];
    var newFilter = filter;
    if (filterId === "perPage")
      newFilter[filterId] = parseInt(event.target.value);
    else if (filterId === "page")
      newFilter[filterId] = parseInt(event.target.id.split("-")[2]);
    setFilter(newFilter);
    fetchData();
  }

  const downloadUsers = async () => {
    let params = filter;
    params.download = true 
    let data = await axios(
      "/api/" + type, { params: params }
    ).then((result) => {
      return result.data;
    })
    console.log(data)
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
  }


  return (<Container>
    {!isLogged(user) ?
      <Redirect to="/login" /> : data ? <React.Fragment>
        {(type === "forms" && !isAdmin(user)) && <h6>Schede salvate: {count}, il tuo limite è {limit} </h6>}
        {(type === "users" && isAdmin(user)) && 
        <React.Fragment><h6>Scarica la lista degli utenti: </h6>
        <Button variant="success"  onClick={downloadUsers}>
          Download
        </Button>
        </React.Fragment>}
        <BrowserTable type={type} filter={filter} onChange={filterChange} columns={columns[type]} values={data} />
        <BrowserTablePagination onClick={changePage} end={data.length === 0} page={filter.page ? filter.page : 1} />
      </React.Fragment> : <Spinner size="lg" animation="grow" />}</Container>
  );
}



