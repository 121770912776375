import React, { useContext, useRef } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { isLogged } from "../../auth"
import axios from "axios";
import { Redirect } from "react-router-dom";
import { UserContext } from "../UserContext"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { recoverySchema } from "../../validation"
import ReCAPTCHA from "react-google-recaptcha"

export default function PasswordRecovery(props) {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(recoverySchema) });
    const { user, setFeedback } = useContext(UserContext);
    const reRef = useRef();
    const handleRecovery = async (data) => {
        try {
            const recaptcha = await reRef.current.executeAsync();
            reRef.current.reset();
            const token = props.location.search.substr(1, props.location.search.length);
            const email = data.email;
            const password = data.password;
            const password2 = data.confirmPassword;
            const res = await axios.post("/api/auth/recover", { token: token, email: email, password: password, password2: password2, recaptcha: recaptcha })

            if (res.status === 200) {
                history.push("/");
                setFeedback({ variant: "success", message: "Registrazione completata con successo" })
            } else {
                setFeedback({ variant: "danger", message: "Errore durante la registrazione, riprova" });
            }

        }
        catch (error) {
            setFeedback({ variant: "danger", message: "Errore durante la registrazione, riprova" });
        }
    };

    return (
        <React.Fragment>
            {isLogged(user) && <Redirect to="/" />}
            <Form onSubmit={handleSubmit(handleRecovery)}>
                <h1>Recupero Password</h1>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={register} type="email" name="email" placeholder="name@example.com" />
                    {errors.email && <Badge>{errors.email.message}</Badge>}
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control ref={register} name="password" type="password" placeholder="Password" />
                    {errors.password && <Badge>{errors.password.message}</Badge>}
                </Form.Group>
                <Form.Group controlId="password2">
                    <Form.Label>Conferma la Password</Form.Label>
                    <Form.Control ref={register} name="confirmPassword" type="password" placeholder="Password" />
                    {errors.confirmPassword && <Badge>{errors.confirmPassword.message}</Badge>}
                </Form.Group>

                <Button variant="outline-danger" type="submit" style={{ margin: "1%" }}> Recupera </Button>
            </Form>
            <ReCAPTCHA size="invisible" ref={reRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />

        </React.Fragment>
    );
}