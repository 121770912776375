import React, { useContext, useRef } from "react";
import { Form, Button, Badge, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../UserContext"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "../../validation"
import Agreement from "./Agreement"
import ReCAPTCHA from "react-google-recaptcha"

export default function Register() {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(registerSchema) });
    const { setFeedback } = useContext(UserContext);
    const reRef = useRef();

    const handleRegister = async (data) => {
        try {


            const recaptcha = await reRef.current.executeAsync();
            reRef.current.reset();
            const email = data.email;
            const password = data.password;
            const password2 = data.confirmPassword;
            const res = await axios.post("/api/auth/register", { email: email, password: password, password2: password2, recaptcha: recaptcha })

            if (res.status === 200) {
                history.push("/");
                setFeedback({ variant: "success", message: "Registrazione completata con successo, ti abbiamo inviato un'email all'indirizzo " + email + " per verificare il tuo account" })
            } else {
                setFeedback({ variant: "danger", message: "Errore durante la registrazione, riprova" });
            }

        } catch (error) {
            setFeedback({ variant: "danger", message: "Errore durante la registrazione, riprova" });
        };
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title>Registrati a IQMIndex.com</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit(handleRegister)}>

                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control ref={register} type="email" name="email" placeholder="name@example.com" />
                        {errors.email && <Badge>{errors.email.message}</Badge>}
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control ref={register} name="password" type="password" placeholder="Password" />
                        {errors.password && <Badge>{errors.password.message}</Badge>}
                    </Form.Group>
                    <Form.Group controlId="password2">
                        <Form.Label>Conferma la Password</Form.Label>
                        <Form.Control ref={register} name="confirmPassword" type="password" placeholder="Password" />
                        {errors.confirmPassword && <Badge>{errors.confirmPassword.message}</Badge>}
                    </Form.Group>
                    <Form.Group controlId="terms">
                        <Form.Label>Termini e Condizioni</Form.Label>
                        <Form.Text>Accetta termini e condizioni di utilizzo per completare la registrazione</Form.Text>
                        <Agreement />
                        <Form.Check ref={register} name="terms" label="
                    Dichiaro di aver letto e accettato 
                    termini e condizioni di utilizzo e 
                    l'informativa sulla privacy 
                    "/>

                        {errors.terms && <Badge>{errors.terms.message}</Badge>}
                    </Form.Group>

                    <Button variant="outline-danger" type="submit" style={{ margin: "1%" }}> Registrati </Button>
                </Form>
            </Card.Body>
            <Card.Footer>
                <p><Link to="/login"> Sei già registrato? Clicca qui </Link></p>
                <ReCAPTCHA size="invisible" ref={reRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />
            </Card.Footer>
        </Card>
    );
}