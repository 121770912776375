import React from "react";
import { Card, Image, ListGroup, Row, Col, Tab } from "react-bootstrap";
import ResultViewer from "./ResultViewer";
import GeometryFormViewer from "./GeometryFormViewer";
import { categories } from "../../../utils"
import ParameterListViewer from "./ParameterListViewer"
export default function FormViewerBody({ form }) {


  return (
    <Tab.Container id="list-group-tabs-example">
      <Row>
        <Col sm={4}>
          <ListGroup>
            <ListGroup.Item action href="#photos">
              Foto
              </ListGroup.Item>
            <ListGroup.Item action href="#diagrams">
              Schemi
              </ListGroup.Item>
            <ListGroup.Item action href="#materials">
              Materiali
              </ListGroup.Item>
            <ListGroup.Item action href="#description">
              Descrizione
              </ListGroup.Item>
            <ListGroup.Item action href="#parameters">
              Valutazione Parametri
              </ListGroup.Item>
            <ListGroup.Item action href="#result">
              Risultati
              </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col sm={8}>
          <Tab.Content>
            <Tab.Pane eventKey="#photos">
              <Card.Body>
                <Card.Title>Foto</Card.Title>
                <ListGroup>
                  {form.photos && form.photos.map(
                    (item, index) => { return (<Col key={item._id + "col" + index} lg={6} md={6} xs={12}><ListGroup.Item key={item._id + "list"}><Image className="center" style={{ maxHeight: "auto", maxWidth: "auto", objectFit: "scale-down" }} src={process.env.REACT_APP_BACKEND+item} /></ListGroup.Item></Col>) }
                  )}
                </ListGroup>

              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="#diagrams">
              <Card.Body>
                <Card.Title>Schemi</Card.Title>
                <ListGroup>
                  {form.diagrams && Object.keys(form.diagrams).map(
                    (item, index) => {
                      return (!form.diagrams[item] ? null : <ListGroup.Item key={index} >{item === "section" ? "Sezione" : item === "front" ? "Frontale" : "Assonometria"}<br /><Image style={{ maxHeight: "auto", maxWidth: "300px", objectFit: "scale-down" }} src={process.env.REACT_APP_BACKEND+form.diagrams[item]} /></ListGroup.Item>)
                    }
                  )}
                </ListGroup>
              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="#materials">
              <Card.Body>
              <Card.Title>Tipologia muraria</Card.Title>
                <p>{categories[form.masonryCategory] || "Non specificata"}</p>
                {(form.materials && form.materials.length !== 0) &&
                <Card.Title>Descrizione dei materiali</Card.Title>
                }
                <ListGroup>
                  {(form.materials && form.materials.length !== 0) && form.materials.map(
                    (item, index) => { return (<ListGroup.Item key={index}>{item}</ListGroup.Item>) }
                  )}
                </ListGroup>
                <GeometryFormViewer defaultValue={form ? form.geometry : null} />
                
                <Card.Title>Parametri Correttivi</Card.Title>
                  <p>Correzione per malta di pessima qualità: {form.correctiveParameters && form.correctiveParameters.m ? "Applicata" : "Non Applicata"}</p>
                  <p>Correzione per blocchi pieni o equivalenti: {form.correctiveParameters && form.correctiveParameters.r ? "Applicata" : "Non Applicata"}</p>  
                  <p>Correzione per giunti ampi ({">"}13mm): {form.correctiveParameters && form.correctiveParameters.g ? "Applicata" : "Non Applicata"}</p>  
              
              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="#description">
              <Card.Body>
                <Card.Title>Descrizione</Card.Title>
                <Card.Body>{form.description}</Card.Body>

              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="#parameters">
              <Card.Body>
                <Card.Title>Valutazione Parametri</Card.Title>
                <Card.Body>
                  <ParameterListViewer values = {form.artRuleParameters} />
                  
                  <b>Valutazione di P.D.</b>
                  {form.lmt && form.lmt.PD && form.lmt.PD.use && <p>Tramite LMT. Valore : {form.lmt.PD.value} cm</p>}
                  {form.lmt && form.lmt.PD && !form.lmt.PD.use && <p>Manuale</p>}
                  <b>Valutazione di S.G.</b>
                  {form.lmt && form.lmt.PD && form.lmt && form.lmt.SG && form.lmt.SG.use && <p>Tramite LMT. Valore : {form.lmt.SG.value} cm</p>}
                  {form.lmt && form.lmt.PD && !form.lmt.SG.use && <p>Manuale</p>}
                  </Card.Body>

              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="#result">
              <ResultViewer masonryCategory={form.masonryCategory} result={form.result} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
