import React, { useContext } from "react"
import {Route, Redirect } from "react-router-dom"
import {UserContext} from "./components/UserContext"
import {isAdmin} from "./auth"

export default function AdminRoute({children, exact, path, component}){
    const {user} = useContext(UserContext);

    return(
        isAdmin(user, true) ?
        <Route exact={exact} path={path} component={component}>
            {children}
        </Route> :
        <React.Fragment>
            <Redirect  to="/"/>
        </React.Fragment>

    )
}