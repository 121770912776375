import React, {  useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes"
import { Container, Spinner } from "react-bootstrap"
import Header from "./components/Header";
import Footer from "./components/Footer"
import Cookie from "./components/Cookie"
import Feedback from "./components/Feedback"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { renew, UserContext } from "./components/UserContext"
import axios from "axios";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND;
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);
  const [analytics, setAnalytics] = React.useState(false);
  

  useEffect(() => {
    const getUser = async () => {
      const loggedUser = await renew();
      setUser(loggedUser);
      setLoading(false);
    }
    getUser().catch(error => {
      setFeedback({variant: "danger", message: "Errore"});
      setUser(null);
      setAnalytics(false);
      localStorage.clear();
    });
  }, [setUser]);
  const [feedback, setFeedback] = React.useState(null);
  

  return (
    loading ? <Container><Spinner animation="grow" size="lg" /></Container> :
      <UserContext.Provider value={{ user, setUser, feedback, setFeedback, analytics, setAnalytics }}>

        <BrowserRouter >
          <Header />
          <Container style={{ display: "flex", flexDirection: "column", marginTop: "5%", marginBottom: "5%" }}>
            <Cookie />
            <Feedback feedback = {feedback}/>
            <Routes />
          </Container>
          <Footer />
        </BrowserRouter>

      </UserContext.Provider>
  );
}

export default App;
