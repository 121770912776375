import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Form } from "react-bootstrap"
export default function FormInput({ onChange = () => { }, type, label = "", name, as = "input", plaintext = false, defaultValue = "", size = "lg", disabled = false }) {
  const { register } = useFormContext()

  return (
    <React.Fragment >

      <Form.Group>
        <Form.Label >{label}</Form.Label>

        <Form.Control onChange={onChange} defaultValue={defaultValue} disabled={disabled} plaintext={plaintext} type={type} name={name} as={as} ref={register} size={size} />
      </Form.Group>
    </React.Fragment>
  )
}
