import React from "react"
import {useFormContext} from "react-hook-form"
import {Form} from "react-bootstrap"
import {categories} from "../../../utils"
export default function MasonryCategory({defaultValue}) {
    const { register } = useFormContext();
    return(
        <React.Fragment>
        <Form.Group>
        <Form.Label>Tipologia Muraria</Form.Label>
        <Form.Control defaultValue = {defaultValue || 0} 
        name = "masonryCategory" 
        ref = {register} 
        as = "select">
                {categories.map((type, index) => (
                <option value = {index} key ={"masCat"+index} >{type}</option>
                ))}
        </Form.Control>
        </Form.Group>
        </React.Fragment>
    )
}