import { useContext, useRef } from "react";
import { Button, Form, Badge, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Redirect } from "react-router-dom";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { loginSchema } from "../../validation"
import { UserContext } from "../UserContext"
export default function Login() {
    const { user, setUser, setFeedback } = useContext(UserContext);
    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(loginSchema) });
    const reRef = useRef();

    const handleLogin = async (data) => {

        const recaptcha = await reRef.current.executeAsync();
        reRef.current.reset();

        const email = data.email;
        const password = data.password;
        try {
            const res = await axios.post("/api/auth/login", { email: email, password: password, recaptcha: recaptcha })
            if (res.status === 200) {
                localStorage.setItem("user", JSON.stringify(res.data));
                setUser(res.data);
                setFeedback({ variant: "success", message: "Accesso effettuato correttamente" })
            } else if (res.status === 250) {
                setFeedback({ variant: "warning", message: "Il tuo account non risulta verificato!" })
            }
        } catch (error) {
            setFeedback({ variant: "danger", message: "Errore di autenticazione" });

        }
    };

    return (
        <Card>
            <Card.Header>
                {user && <Redirect to="/" />}

                <Card.Title>Accedi a IQMIndex.com</Card.Title>
            </Card.Header>
            <Card.Body><Form onSubmit={handleSubmit(handleLogin)}>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={register} type="email" name="email" placeholder="name@example.com" />
                    {errors.email && <Badge>{errors.email.message}</Badge>}
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control ref={register} type="password" name="password" placeholder="Password" />
                    {errors.password && <Badge>{errors.password.message}</Badge>}
                </Form.Group>
                <Button variant="outline-danger" type="submit" style={{ margin: "1%" }}> Accedi </Button>
            </Form>
            </Card.Body>
            <Card.Footer>
                <p><Link to="/password-recovery"> Hai dimenticato la Password?</Link></p>
                <p><Link to="/account-verification"> Reinvia Email di Verifica</Link></p>
                <p><Link to="/register"> Non sei ancora registrato? Clicca qui </Link></p>  
                <ReCAPTCHA size="invisible" ref={reRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />
            </Card.Footer>
        </Card>
    );
}