import React, { useCallback, useContext, useEffect } from "react"
import { useHistory, Switch, Route } from "react-router-dom"
import ReactGA from "react-ga"
import FormEditor from "./components/body/forms/FormEditor";
import FormViewer from "./components/body/forms/FormViewer";
import UserViewer from "./components/body/users/UserViewer";
import RequestToken from "./components/body/RequestToken"
import WaitTokenVerify from "./components/body/WaitTokenVerify";
import PasswordRecovery from "./components/body/PasswordRecovery";
import Home from "./components/Home";
import Browser from "./components/Browser";
import Login from "./components/body/Login";
import Register from "./components/body/Register";
import { UserContext } from "./components/UserContext";
import BulkPromote from "./components/body/users/BulkPromote";
import PrivacyPolicy from "./components/body/PrivacyPolicy";
import Terms from "./components/body/Terms";
import ProtectedRoute from "./ProtectedRoute"
import AdminRoute from "./AdminRoute"
import AnonymousRoute from "./AnonymousRoute"

export default function Routes() {
  const history = useHistory()
  const { analytics , setAnalytics} = useContext(UserContext);
  
  useEffect(useCallback(() => {
    const checkAnalytics = () => {
      const isAnalyticsOk = localStorage.getItem("accept-analytics");
      if(isAnalyticsOk)
        setAnalytics(true);
      else if (analytics) {
        localStorage.setItem("accept-analytics", true);
        ReactGA.initialize('UA-180083402-1');
        history.listen(location => {
          ReactGA.set({ page: location.pathname }); // Update the user's current page
          ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });
      }
      else {
        localStorage.setItem("accept-analytics", false);
      }
    };
    checkAnalytics();
  }, [analytics, history, setAnalytics]))

  return (
    <Switch>
      <Route exact path="/"><Home /></Route>
      <ProtectedRoute path="/forms/editor/:action" component={FormEditor} />
      <ProtectedRoute path="/forms/view" component={FormViewer} />
      <ProtectedRoute exact path="/forms"><Browser type="forms" /></ProtectedRoute >
      <AnonymousRoute exact path="/password-recovery"><RequestToken type="sendrecover" /></AnonymousRoute>
      <AnonymousRoute exact path="/account-verification"><RequestToken type="verify" /></AnonymousRoute>
      <AnonymousRoute path="/verify" component={WaitTokenVerify} />
      <AnonymousRoute path="/recover" component={PasswordRecovery} />
      <AdminRoute exact path="/users"  ><Browser type="users" /></AdminRoute>
      <AdminRoute exact path="/promote" component = {BulkPromote} />
      <ProtectedRoute path="/users/view" component={UserViewer} />
      <AnonymousRoute path="/login"><Login /></AnonymousRoute>
      <AnonymousRoute path="/register" ><Register /></AnonymousRoute>
      <Route path="/terms"><Terms /></Route>
      <Route path="/privacy" ><PrivacyPolicy /></Route>
    </Switch>
  )
}