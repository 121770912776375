
import React, { useEffect } from "react"
import { Spinner,Container, Row, Col } from "react-bootstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { UserContext } from "../UserContext"

export default function WaitTokenVerify(props) {
    const history = useHistory();
    const {setFeedback} = React.useContext(UserContext);

    useEffect(() => {
        const token = props.location.search.substr(1,props.location.search.length-1)
        axios.get("/api/auth/verify", {params: {token: token}})
        .then((res) => {
            if(res.status < 400)
                setFeedback({variant: "success", message: "Verifica dell'email completata!"})
                history.push("/login");
            
        }).catch(() => {
            setFeedback({variant: "danger", message: "Verifica dell'email fallita!"})
            history.push("/");
        })
    }, [history, props.location.search, setFeedback])    
    return (
    <Container>
        <Row>
            <Col>
                <Spinner size="lg"  animation="grow"/>
                <h6>Stiamo verificando la tua richiesta...</h6>
            </Col>
        </Row>
    </Container>)
}