import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import FileInput from "./FileInput";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import CameraUpload from "./CameraUpload";
export default function FormFileInput({ defaultValue = null, hidden = false, size = "sm", multiple = true, showLabel = true, label, name, accept = "image/*" }) {
  const { setValue } = useFormContext()
  const [showCamera, setShowCamera] = useState(false);

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue])

  return (
    <React.Fragment>
      <Form.Label >{showLabel && label}</Form.Label>
      <Form.Group>
        <ButtonGroup>
          <Button variant = "outline-danger" style = {{marginBottom: 0}} hidden={hidden} as={"label"} size={size} htmlFor={name + "_input"}> {"Carica " + label}</Button>
          {accept === "image/*" && <Button variant = "outline-danger" hidden = {hidden} size = {size}  onClick={() => { setShowCamera(!showCamera) }}>{!showCamera ? "Scatta una foto" : "Chiudi"}</Button>}
        </ButtonGroup>
        <Controller as={FileInput} name={name} size="lg" />

      </Form.Group>
      {accept === "image/*" && <CameraUpload showCamera = {showCamera} name = {name} multiple = {multiple} setShowCamera = {setShowCamera}/>}
    </React.Fragment>
  )
}