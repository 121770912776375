import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { doLogout, isAdmin, isLogged } from "../auth";
import { UserContext } from "./UserContext";
import "./Header.css";
export default function Header() {

    const { user, setUser } = React.useContext(UserContext);
    return (
        <Navbar expand="lg" >
            <Navbar.Brand><Link to="/">
                <img
                    src="/logow.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="IQM logo"
                />{' '}
                IQMIndex.com
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Link className="nav-link" to="/">Home</Link>
                    {isLogged(user) &&
                        <NavDropdown title="Schede" id="forms-nav-dropdown">
                            <Link className="dropdown-item"  to="/forms/editor/new" >Nuova Scheda</Link>
                            <Link className="dropdown-item"  to="/forms">Cerca Schede</Link>
                        </NavDropdown>}

                    {isAdmin(user, true) &&
                        <NavDropdown title="Amministrazione" id="users-nav-dropdown">
                            <Link className="dropdown-item" to="/users">Cerca Utenti</Link>
                            <Link className="dropdown-item" to="/promote">Promozione Massiva Utenti</Link>
                        </NavDropdown>
                    }
                    {!isLogged(user) && <Link className="nav-link" to="/login">Accedi</Link>
                    }
                    {!isLogged(user) && <Link className="nav-link" to="/register">Registrati</Link>
                    }
                    {isLogged(user) && <Link className="nav-link" to="/users/view">Profilo</Link>
                    }
                    {isLogged(user) && <NavDropdown title="Aiuto" id="help-nav-dropdown">
                        <a className="dropdown-item" download href="/pdf/Manuale_IQMIndex.pdf" >Manuale dell'applicazione</a>
                        <a className="dropdown-item"  download href="/pdf/Guida_IQM.pdf" >Guida IQM</a>
                        <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCTGhHyLX0z_rx3DyJyvNPcA" >Video</a>
                    </NavDropdown>}
                    {isLogged(user) && <Link className="nav-link" onClick={() => { doLogout(setUser) }} to="/">Logout</Link>}
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    )
}
