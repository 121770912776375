import React, { useContext } from "react"
import { useFormContext } from "react-hook-form";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import axios from "axios";
import { UserContext } from "../../UserContext";

export default function PreviewList({ defaultValue, watched, multiple = true, toUpload = false }) {
    const { setFeedback } = useContext(UserContext);
    const { watch, setValue, getValues } = useFormContext();
    const list = watch(watched, defaultValue);
    const handleDelete = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const toDelete = event.target.id.split('-')[1];

        if (toDelete) {

            axios.delete("api/resources/", {params :{ file: toDelete }}).then((res) => {
                if (res.status >= 400) {
                    setFeedback({ variant: "danger", message: "Qualcosa è andato storto!" });
                }
                else {
                    setFeedback({ variant: "success", message: "File eliminato con successo" });
                    if (multiple) {
                        var list = getValues(watched);
                        if (!list)
                            list = defaultValue
                        list.splice(list.indexOf(toDelete), 1);
                        setValue(watched, list, { shouldValidate: true, shouldDirty: true });
                    } else {
                        setValue(watched, null, { shouldDirty: true, shouldValidate: true });
                    }
                }
            }).catch((error) => {
                setFeedback({ variant: "danger", message: "Qualcosa è andato storto!" });
            });
        }
    };

    return (
        (!list ? null : list &&
            multiple ?
            <Row>
                {list.map((elem, index) => (
                    <Col md={6}
                        xs={12}
                        lg={3} key={index + elem + "col"}>
                        <Container key={index + elem + "container"}>
                            <Card key={index + elem + "card"}>
                                <Card.Header key={index + elem + "card_header"}>
                                    <Button key={index + elem + "button"} block variant="danger" id={"delete-" + list} onClick={handleDelete}>Elimina</Button>
                                </Card.Header>
                                <Card.Img variant="top"
                                    style={{
                                        maxHeight: "auto",
                                        maxWidth: "auto",
                                        objectFit: "scale-down",
                                    }}


                                    src={process.env.REACT_APP_BACKEND + elem}

                                    key={index + watched}
                                    alt="..." />

                            </Card>

                        </Container>
                    </Col>

                ))}

            </Row> : list &&

            <Card>
                <Card.Img
                    style={{ height: "300px", maxWidth: "300px", objectFit: "scale-down" }}


                    src={process.env.REACT_APP_BACKEND + list} alt="..." />

                {toUpload && <Card.Footer>
                    <Button block variant="danger" id={"delete-" + list} onClick={handleDelete}>Elimina</Button>
                </Card.Footer>}
            </Card>

        ))

}