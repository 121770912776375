import React , {useEffect} from "react";
import {Form, InputGroup , Col, Row } from "react-bootstrap"            
import { useFormContext } from "react-hook-form";

export default function GeometryForm ({defaultValue = null}){
    const {register, setValue} = useFormContext();
    useEffect(()=>{
       if(defaultValue){
            setValue("geometry", defaultValue);
        }
    },[defaultValue, setValue])
    return(
        <Form.Group>
            <Form.Label>Dimensioni ricorrenti degli elementi (in centimetri)</Form.Label  >
            <InputGroup  >
            <Row>
                <Col lg = {4} md = {4} xs = {12}>
                <Form.Label> Altezza </Form.Label>
                <Form.Text>Min</Form.Text><Form.Control  defaultValue = {defaultValue ? defaultValue.h.min : 0 } name = "geometry.h.min"  ref = {register} as="input" step = "any" type="number" />
                <Form.Text>Max</Form.Text><Form.Control  defaultValue = {defaultValue ? defaultValue.h.max : 0 } name = "geometry.h.max"  ref = {register} as="input" step = "any" type="number" />
                </Col>
                <Col lg = {4}  md = {4} xs = {12}>
                <Form.Label> Lunghezza </Form.Label>
                <Form.Text>Min</Form.Text><Form.Control  defaultValue = {defaultValue ? defaultValue.l.min : 0 } name = "geometry.l.min"   ref = {register} as="input" step = "any" type="number"  />
                <Form.Text>Max</Form.Text><Form.Control  defaultValue = {defaultValue ? defaultValue.l.max : 0 } name = "geometry.l.max"   ref = {register} as="input" step = "any" type="number"  />
                </Col>
                <Col lg = {4}  md = {4} xs = {12}><Form.Label> Profondità </Form.Label>
                <Form.Text>Min</Form.Text><Form.Control  defaultValue = {defaultValue ? defaultValue.p.min : 0 } name = "geometry.p.min"   ref = {register} as="input" step = "any" type="number"  />
                <Form.Text>Max</Form.Text><Form.Control  defaultValue = {defaultValue ? defaultValue.p.max : 0 } name = "geometry.p.max"   ref = {register} as="input" step = "any" type="number"  />
                </Col>
                </Row>
            </InputGroup>
        </Form.Group>
    )
}
            