import React, { useState, useEffect } from "react";
import { Card, Button, ButtonGroup, Container, Spinner } from "react-bootstrap";
import FormViewerBody from "./FormViewerBody";
import { isLogged, isAdmin } from "../../../auth"
import { Redirect } from "react-router-dom"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { UserContext } from "../../UserContext";

export default function FormViewer(props) {
    const history = useHistory();
    const { user, setFeedback } = React.useContext(UserContext);
    const [form, setForm] = useState(null);

    useEffect(() => {
        const id = props.location.search.substr(1, props.location.search.length);

        axios.get("/api/forms/view", { params: { id: id } })
            .then((result) => {
                setForm(result.data);
            }).catch(() => {
                setFeedback({ variant: "danger", message: "Impossibile trovare la scheda specificata" })
               
            });
    }, [props.location.search, setFeedback]);

    const deleteForm = () => {
        axios.delete("/api/forms/", { params: { id: form._id } })
            .then((res) => {
                if (res.status < 400) {
                    setFeedback({ variant: "success", message: "Scheda eliminata con successo!" })
                    history.push({ pathname: "/forms/" })
                } else throw new Error()
            }).catch(() => {
                setFeedback({ variant: "danger", message: "Impossibile eliminare la scheda specificata"})
            });
    }

    const handleCopy = () => {
        axios.get("/api/forms/copy", { params: { id: form._id } })
            .then((res) => {
                if (res.status < 400) {
                    if (res.status === 250) {
                        setFeedback({
                            variant: "warning", 
                            message: "Scheda non copiata! Hai raggiunto il limite di schede consentito"})
                    } else {
                    setFeedback({ variant: "success", message: "Scheda copiata con successo!" })
                    history.push(
                        {
                            pathname: "/forms/editor/modify",
                            search: res.data.id,
                        });
                    }
                } else throw new Error()
            }).catch(() => {
                setFeedback({ variant: "danger", message: "Impossibile copiare la scheda specificata"})
                return null;
            });
    }


    const downloadForm = async () => {
        try {
            const res = await axios.get("/api/forms/download", { params: { id: form._id }, responseType: "blob" })
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([res.data]));
            link.setAttribute("download", "report.pdf");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            setFeedback({ variant: "danger", message: "Impossibile scaricare la scheda specificata" })
            
        }
    }


    return (!isLogged(user) ? <Redirect to="/login" /> :
        !form ?
            <Spinner animation="grow" size="lg" /> :
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <h3>Nome: {form.name}</h3>
                            <h5>Autore: {form.author} </h5>
                            <h5>CAP: {form.zone ? form.zone : ""}</h5>
                        </Card.Title>
                        <ButtonGroup size="sm">
                            <Button variant="success" onClick={downloadForm}>Download</Button>
                            {(form.author === user.email) &&
                                <Button variant="outline-danger" onClick={() => history.push(
                                    {
                                        pathname: "/forms/editor/modify",
                                        search: form._id,
                                    })}>
                                    Modifica</Button>}
                            {(form.author === user.email || isAdmin(user)) &&
                                <Button variant="outline-danger" onClick={() => handleCopy() }>
                                    Usa come Fac-Simile</Button>}
                            {(form.author === user.email || isAdmin(user)) && <Button variant = "danger" onClick={deleteForm}>Elimina</Button>}

                        </ButtonGroup>
                    </Card.Header>
                    <FormViewerBody form={form} />
                </Card>
            </Container>

    );
}
