import Axios from "axios";
import React from "react"
import {Container, Card, Form, Button } from "react-bootstrap";
import { UserContext } from "../../UserContext"

export default function BulkPromote() {
    const { setFeedback } = React.useContext(UserContext);
    const fileRef = React.useRef();
    const handleSubmit = (event) => {
        event.preventDefault();

        var data = new FormData();
        data.append("file", fileRef.current.files[0]);
        
        Axios({method: "post", 
        url : "api/users/promote", 
        headers: {'Content-Type': 'multipart/form-data' },
        data: data})
        .then((response) => {
            if(response.status>=400)
                throw new Error();
            setFeedback({variant: "success", message: "Operazione completata:\n "+
            "Totali:" + response.data.total+"\n"+
            "Inseriti:" + response.data.success+"\n"+
            "Errori:" + response.data.failed+"\n"
            })
        })
        .catch(() => setFeedback({variant: "danger", message: "Qualcosa è andato storto"})) ;
    }
    return (
       
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title>Promozione Massiva Utenti</Card.Title>
                        <Card.Text>
                            Promuovi multeplici utenti al grado ADVANCED
                            caricando un file .csv con email e data di scadenza
                            della promozione in formato AAAA-MM-GG<br /> 
                            <a href="/templates/template.csv" download>Scarica il template</a>
                        </Card.Text>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit = {handleSubmit} >
                        <Form.Group> 
                            <Form.Label>Carica il file</Form.Label>
                           <Form.File ref = {fileRef} accept="text/csv" />
                        </Form.Group>
                        <Button variant = "outline-danger" type = "submit">Carica</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>

    );
}