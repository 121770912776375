import React from "react";
import { useFormContext } from "react-hook-form"
import { OverlayTrigger, Tooltip, Row, Col, InputGroup, Form } from "react-bootstrap"
import { parameterList } from "../../../parameterList";

export default function ParameterList({ defaultValues }) {
    const { register, watch } = useFormContext();
    const PD = watch("lmt.PD.use", false);
    const SG = watch("lmt.SG.use", false);
    return (
        <Form.Group>
        <Form.Label>Parametri della regola dell'arte</Form.Label>
        <Row fluid>
            {
                parameterList.map((param, index) => (

                    <Col style={{padding: "1%"}}hidden = {param.short === "P.D." ? PD : param.short === "S.G." ? SG : false}
                    s={true} md={true} lg={true} key={"parameter_col" + index}  >
                        <InputGroup>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{param.long}</Tooltip>}>
                            <span className="d-inline-block">
                                <InputGroup.Prepend>
                                <InputGroup.Text style={{ pointerEvents: 'none' }} key={"parameter_name" + index} >{param.short}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </span>
                        </OverlayTrigger>

                        <Form.Control
                            as="select"
                            key={"parameter_value" + index}
                            name={"artRuleParameters." + param.short.split('.').join("")}
                            defaultValue={defaultValues ? defaultValues[param.short.split('.').join("")] : "NR"}
                            ref={register} >

                            <option>NR</option>
                            <option>PR</option>
                            <option>R</option>

                        </Form.Control>
                        </InputGroup>
                    </Col>
                ))}
        </Row>
        </Form.Group>
    );
};
