import React from "react"
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import "./Footer.css"
export default function Footer() {
    const email = process.env.REACT_APP_EMAIL
    const mailto = `mailto:${email}`;
    return (

        <footer className="container-fluid px-4">
            <Row>
                <Col>
                    <h5>Contattaci</h5>
                    <p>Per supporto o informazioni scrivere a
                            <a className="text-white sticky-bottom"
                            href={mailto}> <b>{email}</b>
                        </a>
                    </p>
                    <h5><Link className="text-white sticky-bottom" to="/privacy" >Privacy</Link></h5>
                    <h5><Link className="text-white sticky-bottom" to="/terms" >Termini e Condizioni</Link></h5>
                </Col>
                <Col><p>
                    Orgogliosamente sviluppato da Nicola Ferrante per
                        <a href="http://www.aedes.it" target="blank"> <img src="/logo_aedes.png" alt="Aedes Software s.n.c." /></a>
                </p>
                </Col>
            </Row>
        </footer>

    )
}
