import React from "react";
import {  Table } from "react-bootstrap";
import { isAdmin } from "../auth"
import TableEntry from "./TableEntry";
import TableHeader from "./TableHeader";
import { UserContext } from "./UserContext";

export default function BrowserTable(props) {
    const {user} = React.useContext(UserContext);
    return (
        
            <Table className = "bg-white" hover responsive bordered>
                <thead>
                    <tr>
                        {props.columns.length !== 0 &&
                            props.columns.map((header, index) => {
                                return (<TableHeader 
                                    index={index}
                                    header={header}
                                    onChange={props.onChange}
                                />);
                            })
                        }
                    </tr>
                </thead>

                <tbody>


                    {props.values.length === 0 ? <tr ><td colSpan={isAdmin(user) ? "3" : "2"}>Nessun elemento da visualizzare</td></tr> : props.values.map((value, index) => {
                        return (<TableEntry admin = {props.admin} key = {index+"entry"} type={props.type} headers={props.columns} value={value} />);
                    })}

                </tbody>
            </Table>
       
    )
}
