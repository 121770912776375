import React, {useContext} from "react"
import { Button, ButtonGroup, Modal } from "react-bootstrap"
import {UserContext} from "./UserContext"

export default function Cookie() {
    const {setAnalytics} = useContext(UserContext)
    const [cookie, setCookie] = React.useState(false);
    React.useEffect( () => {
        const checkCookie = async () => {
        const isCookieOk = localStorage.getItem("accept-cookie");
        if (isCookieOk)
          setCookie(true);
        }
        if(cookie){
            localStorage.setItem("accept-cookie", true);
        }
        checkCookie();
      }, [cookie])
    return (
      
        <Modal
            show={!cookie}
            className="sticky-bottom"
            variant="dark"
            backdrop="static"
            keyboard={false}
            onHide={() => { setCookie(true) }}
        >
            <Modal.Header closeButton><Modal.Title>Cookies</Modal.Title></Modal.Header>
            <Modal.Body>
                Questo sito utilizza cookie per garantire le funzionalità
                di accesso in sicurezza per gli utenti.<br />
                Questo sito inoltre utilizza Google Analytics per collezionare 
                statistiche sul funzionamento del sito e Google ReCAPTCHA
                per evitare l'intrusione di utenti automatizzati o semiautomatizzati (Bot)<br />
                I cookie utilizzati da Google ReCAPTCHA e quelli per l'accesso sono obbligatori per
                il funzionamento dell'applicazione. Mentre Google Analytics è accessorio e può essere
                disattivato. L'opzione di default è mantenere attivi tutti i cookie.
                </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick = {() => { setCookie(true); setAnalytics(true) }} variant="outline-danger">Accetto tutti i cookie</Button>
                    <Button onClick = {() => { setCookie(true); setAnalytics(false) }} variant="outline-danger">Accetto solo i cookie necessari</Button>
                </ButtonGroup>
                </Modal.Footer>
        </Modal>);
}