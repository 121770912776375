import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Form } from "react-bootstrap"
export default function FormCheck ({onChange = ()=>{}, label = "", type = "checkbox", name , defaultValue = false }) {
  const { register } = useFormContext()

  return (
    <React.Fragment >
      <Form.Group>  
          <Form.Check label = {label} type = {type} onChange = {onChange} defaultChecked = {defaultValue}  name = {name} ref={register} />
      </Form.Group>
    </React.Fragment>
  )
}