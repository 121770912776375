/* eslint-disable */
import * as yup from "yup";

export const loginSchema = yup.object().shape({
    email: yup.string().email("Inserire un indirizzo email valido").required("Questo campo è richiesto"),
    password: yup
        .string()
        .required("Questo campo è richiesto")
        .min(8, "La password deve contenere almeno 8 caratteri alfanumerici")
        .max(32, "LA password non deve essere più lunga di 32 caratteri")
        .matches(/^(?=[a-zA-Z0-9#@$\?\-\_\.\!\=\^\*\s]{8,}$).*/, "Inserito un carattere non valido"),
});

export const sendEmailSchema = yup.object().shape({
    email: yup.string().email("Inserire un indirizzo email valido").required("Questo campo è richiesto"),
});

export const formSchema = yup.object().shape({
    _id: yup.string().notRequired(),
    name: yup.string().required("Devi inserire un nome")
        .min(4, "Il nome deve essere lungo almeno 4 caratteri")
        .max(64, "Il nome non deve superare i 64 caratteri").trim(),
    author: yup.string().email().required().max(128).trim(),
    zone: yup.string().max(16, "Il cap non può essere più lungo di 16 caratteri")
        .ensure().trim().notRequired().nullable(),
    diagrams: yup.object().shape({
        axonometry: yup.string().nullable().notRequired(),
        section: yup.string().nullable().notRequired(),
        front: yup.string().nullable().notRequired()
    }),
    photos: yup.array().of(yup.string()).max(5, "Puoi caricare al massimo 5 immagini").compact().ensure().notRequired(),
    materials: yup.array().of(yup.string()).max(3).compact().ensure(),
    geometry: yup.object().shape({
        h: yup.object().shape({ min: yup.number().min(0).default(0), max: yup.number().min(0).default(0) }).notRequired(),
        l: yup.object().shape({ min: yup.number().min(0).default(0), max: yup.number().min(0).default(0) }).notRequired(),
        p: yup.object().shape({ min: yup.number().min(0).default(0), max: yup.number().min(0).default(0) }).notRequired(),
    }).nullable().notRequired(),
    description: yup.string().notRequired()
        .max(500, "La descrizione non deve superare i 500 caratteri").trim(),
    masonryCategory: yup.number().integer().min(0).max(10).notRequired().default(0),
    correctiveParameters: yup.object().shape({
        m: yup.bool().default(false),
        g: yup.bool().default(false),
        r: yup.bool().default(false)
    }),
    lmt: yup.object().shape({
        SG: yup.object().shape({
            use: yup.bool().default(false),
            value: yup.number().max(500).default(0)
        }),
        PD: yup.object().shape({
            use: yup.bool().default(false),
            value: yup.number().max(500).default(0)
        }),
    }).nullable().notRequired(),
    artRuleParameters: yup.object().shape(() => {
        let object = {};
        ["PD", "MA", "SG", "REL", "OR", "DEL", "FEL"].map(
            (param) => object[param] = yup.string().length(2)
        );
        return object;
    })
}).noUnknown(true);

export const recoverySchema = yup.object().shape({
    email: yup.string().email("Inserire un indirizzo email valido").required("L'email è obbligatoria"),
    password: yup
        .string()
        .required("La password è obbligatoria")
        .min(8, "La password deve contenere almeno 8 caratteri")
        .matches(/^(?=[a-zA-Z0-9#@$\?\-\_\.\!\=\^\*\s]{8,}$).*/, "Inserito un carattere non valido"),
    confirmPassword:
        yup.string().required().oneOf([yup.ref("password"), null], "Le password devono corrispondere"),
});

export const registerSchema = yup.object().shape({
    email: yup.string().email("Inserire un indirizzo email valido").required("L'email è obbligatoria"),
    password: yup
        .string()
        .required("La password è obbligatoria")
        .min(8, "La password deve contenere almeno 8 caratteri")
        .matches(/^(?=[a-zA-Z0-9#@$\?\-\_\.\!\=\^\*\s]{8,}$).*/, "Inserito un carattere non valido"),

    confirmPassword:
        yup.string().required().oneOf([yup.ref("password"), null], "Le password devono corrispondere"),
    terms: yup.bool().required("Devi accettare i termini e condizioni per procedere")
        .equals([true], "Devi accettare i termini e condizioni per procedere")
});

export const updateUserSchema = yup.object().shape({
    privileges:
        yup.string().when(
            "modifyPrivileges", {
            is: true,
            then: yup.string().required().oneOf(["SIMPLE", "ADVANCED", 'SUPERVISOR', "ADMIN"], "Livello di privilegio non valido")

        }),
    modifyPassword: yup.boolean().default(false),
    modifyPrivileges: yup.boolean().default(false),
    oldPassword: yup.string()
        .required("Inserisci la password corrente per confermare l'operazione")
        .min(8, "La password deve contenere almeno 8 caratteri")
        .matches(/^(?=[a-zA-Z0-9#@$\?\-\_\.\!\=\^\*\s]{8,}$).*/, "Inserito un carattere non valido"),
    password: yup
        .string()
        .when(
            "modifyPassword", {
            is: true,
            then: yup.string()
                .required("La password è obbligatoria")
                .min(8, "La password deve contenere almeno 8 caratteri")
                .matches(/^(?=[a-zA-Z0-9#@$\?\-\_\.\!\=\^\*\s]{8,}$).*/, "Inserito un carattere non valido")
        })


    ,
    confirmPassword:
        yup.string().when(
            "modifyPassword", {
            is: true,
            then: yup.string().required().oneOf([yup.ref("password"), null], "Le password devono corrispondere")
        })

});