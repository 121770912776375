import React, { useContext, useRef } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { isLogged } from "../../auth"
import axios from "axios";
import { Redirect } from "react-router-dom";
import { UserContext } from "../UserContext"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendEmailSchema } from "../../validation"

import ReCAPTCHA from "react-google-recaptcha"
export default function RequestToken({ type = "sendrecover"}) {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(sendEmailSchema) });
    const { user, setFeedback } = useContext(UserContext);
    const reRef = useRef();
    const handleRecover = async (data) => {
        try {

            if (type !== "sendrecover" && type !== "verify")
                history.push("/");

            const recaptcha = await reRef.current.executeAsync();
            reRef.current.reset();
            const email = data.email;

            const res = await axios.post(`/api/auth/${type}`, { email: email, recaptcha: recaptcha });
            console.log(res);
            if (res.status === 200) {
                history.push("/");
                setFeedback({ variant: "success", message: "Ti è stata inviata un email con le istruzioni necessarie" })
            } else {
                setFeedback({variant: "danger", message: "Errore, riprova"});
            }

        }
        catch (error) {
            setFeedback({ variant: "danger", message: "Errore, riprova" });
        }
    };

    return (
        <React.Fragment>
            {isLogged(user) && <Redirect to="/" />}
            <Form onSubmit={handleSubmit(handleRecover)}>
                <h1>Richiedi Email {type === "sendrecover" ? "Di Recupero Password" :
                    "Di Verifica"}</h1>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={register} type="email" name="email" placeholder="name@example.com" />
                    {errors.email && <Badge>{errors.email.message}</Badge>}
                </Form.Group>

                <Button variant = "outline-danger" type="submit" style={{ margin: "1%" }}> Richiedi Email</Button>
            </Form>
            <Link to="/login"> Torna alla pagina di Login </Link>
            <ReCAPTCHA size="invisible" ref={reRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />
        </React.Fragment>
    );
}