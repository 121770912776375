import React, { useContext } from 'react';
import { UserContext } from "../../UserContext"
import { useFormContext } from 'react-hook-form';
import { Form } from "react-bootstrap";
import axios from "axios";
export default function FileInput({ name, multiple = true, accept = "image/*" }) {
  const { setValue, getValues } = useFormContext();
  const { setFeedback } = useContext(UserContext);
  const handleChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      await convertFiles(event.target.files);
    } catch (error) {
      setFeedback({ variant: "danger", message: "Errore durate la preparazione dei file per il caricamento" })

    }
  }

  const convertFiles = async (files) => {
    var converted = {};
    if (!multiple) {
      converted = await upload(files[0]);
      if (converted)
        setValue(name, converted, { shouldValidate: true, shouldDirty: true });

    } else {
      const currFiles = getValues(name);
      var fileObj = currFiles ? currFiles : [];
      for (var i = 0; i < files.length; ++i) {
        converted = await (upload(files[i]));
        if (converted)
          fileObj.push(converted);
      }

      setValue(name, fileObj, { shouldValidate: true, shouldDirty: true });
    }

  }

  const getBase64 = file => new Promise((resolve) => {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => {

      resolve(null);
    }
  });

  const upload = async (file) => {
    if (file.size >= (5 * 1024 * 1024)) {
      setFeedback(
        {
          variant: "danger",
          message: file.name + ": (Dimensione: " + file.size + ") File troppo grande, dimensione massima 5MB"
        });
      return null;
    };

    const datauri = await getBase64(file)
    if (!datauri) {
      setFeedback(
        {
          variant: "danger",
          message: file.name + ": Qualcosa è andato storto durante l'acquisizione dell'immagine"
        });
      return null;
    }
    try {
      var res = await axios.post("api/resources/", { file: datauri });
      if (res.status >= 400) {
        setFeedback({
          variant: "danger",
          message: "Qualcosa è andato storto durante il caricamento dell'immagine"
        });
        return null;
      } else {
        setFeedback({variant: "success", message:"Immagine caricata correttamente"});
        return res.data.filehandle;
      }

    } catch (error) {
        setFeedback({variant: "danger", message:"Qualcosa è andato storto!"});
        return null;
    }
  }

  return (
    <Form.File hidden={true} multiple={multiple} accept={accept} onChange={handleChange} id={name + "_input"} size="lg" />
  )
}