import React, { useContext } from "react";
import { useHistory } from "react-router-dom"
import { isAdmin } from "../auth";
import { UserContext } from "./UserContext";
import TableCell from "./TableCell"
export default function TableEntry(props) {
    const history = useHistory();
    const { user } = useContext(UserContext);

    return (
        <tr onClick={() => history.push(
            {
                pathname: "/" + props.type + "/view",
                search: props.value._id,
            })}>
            {props.headers.map((item, index) => {
                return (
                    (item.accessor !== "author" || isAdmin(user)) &&
                    <TableCell type = {item.type} value = {props.value[item.accessor]}/>
                );
            })}
        </tr>
    )
}