import React, { useRef, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import FormInput from "./FormInput"
import FormButton from './FormButton'
import { Form, Card, Badge } from "react-bootstrap"
import ParameterList from "./ParameterList"
import ResultViewer from "./ResultViewer"
import FormFileInput from "./FormFileInput"
import Diagrams from "./Diagrams"
import CorrectiveParameters from "./CorrectiveParameters"
import { yupResolver } from '@hookform/resolvers/yup';
import GeometryForm from './GeometryForm';
import PreviewList from "./PreviewList";
import axios from "axios"
import MaterialList from "./MaterialList"
import LMT from './LMT'
import { formSchema } from "../../../validation"
import MasonryCategory from "./MasonryCategory"
import { UserContext } from '../../UserContext'
import { useHistory } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha"


export default function _Form({ defaultValues = {}, action = "new" }) {
    const history = useHistory();
    const { setFeedback } = React.useContext(UserContext)
    const [result, setResult] = useState(action === "modify" ? defaultValues.result : null);
    const reRef = useRef();
    const methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(formSchema)
    });

    const masonryCategory = methods.watch("masonryCategory", 0);

    const saveForm = async (data) => {

        try {
            const recaptcha = await reRef.current.executeAsync();
            reRef.current.reset();
            var method = (action === "modify") ? "patch" : "post";
            var _form = data;
            if (action === "modify")
                _form._id = defaultValues._id;
            var res = await axios({ method: method, url: "/api/forms", data: { recaptcha: recaptcha, form: _form } })
            if (res.status === 200) {
                if (action === "new") {
                    history.push("/forms/editor/modify?" + res.data.form._id);
                }

                setResult(res.data.result);
                setFeedback({ variant: "success", message: "Scheda Salvata Correttamente" })
            } else if (res.status === 250) {

                setResult(res.data.result);
                setFeedback({
                    variant: "warning",
                    message: "Scheda non salvata! Hai raggiunto il limite di schede consentito"
                })
            } else {
                setFeedback({ variant: "danger", message: "Qualcosa è andato storto" })
            }
        } catch (error) {
            setFeedback({ variant: "danger", message: "Qualcosa è andato storto" })
        }
    }

    return (defaultValues &&
        <FormProvider {...methods}>

            <Card>
                <Form onSubmit={methods.handleSubmit(saveForm)}>
                    <Card.Header className="sticky-top bg-white">Editor Scheda IQM
                        <ReCAPTCHA size="invisible" ref={reRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />
                        <FormButton className="pull-right" content=" Salva e Calcola IQM" />
                    </Card.Header>

                    <Card.Body>

                        <FormInput defaultValue={defaultValues.author} plaintext={true} type="email" name="author" label="Autore" />
                        <FormInput defaultValue={defaultValues.name} type="text" name="name" label="Nome Scheda" />
                        {methods.errors.name && <Badge variant="danger">{methods.errors.name.message}</Badge>}
                        <FormInput defaultValue={defaultValues.zone} type="text" name="zone" label="CAP" />
                        {methods.errors.zone && <Badge variant="danger">{methods.errors.zone.message}</Badge>}
                        <FormFileInput defaultValue={defaultValues.photos} name="photos" label="Foto" />
                        {methods.errors.photos && <Badge variant="danger">{methods.errors.photos.message}</Badge>}
                        <PreviewList defaultValue={defaultValues.photos} multiple={true} watched="photos" />
                        <MasonryCategory defaultValue={defaultValues.masonryCategory} />
                        <Diagrams defaultValue={defaultValues.diagrams} />
                        <MaterialList defaultValue={defaultValues.materials} />
                        <GeometryForm defaultValue={defaultValues.geometry} />
                        <FormInput defaultValue={defaultValues.description} type="textarea" name="description" label="Descrizione" size="sm" as="textarea" />
                        {methods.errors.description && <Badge variant="danger">{methods.errors.description.message}</Badge>}
                        <LMT defaultValue={defaultValues.lmt} />
                        <ParameterList defaultValues={defaultValues.artRuleParameters} lmt={defaultValues.lmt} />
                        <CorrectiveParameters defaultValue={defaultValues.correctiveParameters} />
                       
                    </Card.Body>
                    <ResultViewer masonryCategory={masonryCategory} result={result} />

                </Form>
            </Card>

        </FormProvider>
    )
}
