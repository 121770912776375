import React from "react"
import { Card, Table } from "react-bootstrap"
import MechanicalParameterViewer from "./MechanicalParameterViewer"
export default function MechanicalParametersViewer(props) {

    return (
        <Card.Body>
            <Card.Title>Parametri Meccanici ( in MPa )</Card.Title>

            <a 
            className="btn btn-outline-danger" 
            download 
            href=
            {`/pdf/masonry/Riferimenti_Parametri_meccanici_Murature_${props.masonryCategory}.pdf`} 
            >
                Visualizza riferimenti normativi parametri meccanici
            </a>
            <Table responsive bordered striped>
                <thead>
                    <tr>
                        <th>Nome</th><th>Min</th><th>Med</th><th>Max</th>
                    </tr>
                </thead>
                <tbody>
                    <MechanicalParameterViewer parameterName="fm" parameter={props.parameters.fm} />
                    <MechanicalParameterViewer parameterName="fv0" parameter={props.parameters.fv0} />
                    <MechanicalParameterViewer parameterName="tau0" parameter={props.parameters.tau0} />
                    <MechanicalParameterViewer parameterName="E" parameter={props.parameters.e} />
                    <MechanicalParameterViewer parameterName="G" parameter={props.parameters.g} />
                </tbody>
            </Table>
        </Card.Body>
    )
}