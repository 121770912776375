import React from "react"
import { Accordion, Card } from "react-bootstrap"
import Terms from "./Terms"
import PrivacyPolicy from "./PrivacyPolicy"
import "./Agreement.css"
export default function Agreement() {
    return (<Accordion>
        <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
                Termini e condizioni di utilizzo
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body><Terms/></Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
                Privacy
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
                <Card.Body><PrivacyPolicy /></Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>
    )
}