import React from "react";
import SchemaChooser from "./SchemaChooser";

import {CardGroup, Form } from "react-bootstrap";

export default function Diagrams({onChange, defaultValue}){

    return(
        <Form.Group>
            <Form.Label>Schemi della Muratura</Form.Label>
        <CardGroup>
            <SchemaChooser defaultValue = {defaultValue ? defaultValue.axonometry : null} onChange = {onChange} direction="axonometry" directionName = "Assonometria"  />
            <SchemaChooser defaultValue = {defaultValue ? defaultValue.section : null} onChange = {onChange} direction="section" directionName = "Sezione"  />
            <SchemaChooser defaultValue = {defaultValue ? defaultValue.front : null} onChange = {onChange} direction="front" directionName = "Frontale" />         
        </CardGroup>
        </Form.Group>
    )
}